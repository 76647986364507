require('./image-uploader.css');
const template = require('./image-uploader.html');

// FlowSrvc is unrelated to flow
// Simple duplication first. Will refactor for also human upload
const ImageUploaderCtrl = ($scope, $location, APISrvc, ReserveDetailsSrvc, CheckInSrvc, FlowSrvc, ModalService) => {
  $scope.vm.checkinSteps = APP_CONFIG.CHECKIN_STEPS_LABELS;
  $scope.vm.checkinStepsUrl = APP_CONFIG.CHECKIN_STEPS_URLS;
  // dirty external state as we need to allow skip
  $scope.vm.isUploading = false;

  $scope.vm.flowAttrs = '{accept:\'image/*\'}';
  $scope.vm.uploadPassport = function(flow) {
    if (flow.files.length > 1) {
      flow.files[0].cancel();
    }
    if (flow.files.length > 0 && flow.files[0].error) {
      flow.files[0].retry();
    } else {
      $scope.vm.isUploading = true;
      flow.upload();
    }
  };

  $scope.vm.submit = function() {
    FlowSrvc.next();
  };

  $scope.showPassportInfoModal = function() {
    ModalService.showModal({
      template: require('./views/checkin_step/passport_info_modal.html'),
      controller: ['$scope', 'close', function($scope, close) {
        $scope.close = close;
      }],
      bodyClass: 'modal-open',
    });
  };

  // $scope.status = null
  $scope.vm.uploadOptions = {
    target: APP_CONFIG.API_PATH + '/upload-passport',
    query: {
      reservation: JSON.stringify(ReserveDetailsSrvc.get().reservation),
    },
    testChunks: false};
};

ImageUploaderCtrl.$inject = [
  '$scope',
  '$location',
  'APISrvc',
  'ReserveDetailsSrvc',
  'CheckInSrvc',
  'FlowSrvc',
  'ModalService',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    isCameraOnly: '@',
  },
  controller: ImageUploaderCtrl,
};
