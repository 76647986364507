const template = require('./chat.html');

require('./chat.css');

const ChatCtrl = function($scope, $http, ReserveDetailsSrvc) {
  // CheckInSrvc.redirectIfOutOfSession();

  $scope.vm.user = ReserveDetailsSrvc.get().guest;
};
ChatCtrl.$inject = ['$scope', '$http', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ChatCtrl,
};
