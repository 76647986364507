const ctrl = function($scope, StripeSrvc, APISrvc) {
  $scope.vm.pay = function() {
    $scope.vm.error = null;
    StripeSrvc.doCheckout({
      amount: $scope.vm.amount * 100,
    // description: $scope.vm.note
    })
        .then(function(results) {
          return APISrvc.charge($scope.vm.amount, $scope.vm.note, results[0])
              .then(function() {
                $scope.vm.success = true;
              });
        })
        .catch(function(err) {
          $scope.vm.error = err;
        });
  };
};

ctrl.$inject = ['$scope', 'StripeSrvc', 'APISrvc'];

module.exports = {
  template: require('./stripe-pos.html'),
  controllerAs: 'vm',
  bindings: {},
  controller: ctrl,
};
