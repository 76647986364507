/*
* Declarative of steps
* idea similar to https://github.com/omichelsen/angular-steps with no use of directives
* Decouple actual route names from controllers
* Make flow more obvious and easier to change
* Harder to represent branch, or skippable steps (determined by external state)
* - possibly use a nested obj OR just break down the flow.
* - for now,, just let it pass in explicit target route(since offset will be leaky)
* Also not allowing 1) continue to another flow 2) refresh will back to entry (can't distinguish between flows)
* common step so keep the state in srvc
* TODO may also work tgt w/ indicator
*/

// Main: happy path, error is error screen where entry is screen to re-enter the steps(e.g. not logged in)
// key is always same as route name (w/ placeholder)
const FLOWS = {
  BOOK_NIGHT: {
    main: [
      '/booking',
      '/booking/date-pick',
      '/booking/select-room',
      '/booking/details',
      '/booking/confirm',
      '/booking/completed',
    ],
    error: '/error',
  },
  BOOK_HOUR: {
    main: [
      '/booking',
      '/booking/date-pick',
      '/booking/details',
      '/booking/confirm',
      '/booking/completed',
    ],
    error: '/error',
  },
  PRECHECKIN_HOUR: {
    main: [
      '/checkin',
      '/checkin/personalize',
      '/checkin/passport',
      '/checkin/review',
      '/checkin/checkedin',
    ],
    error: '/error',
  },
  PRECHECKIN_NIGHT: {
    main: [
      '/checkin',
      '/checkin/arrival',
      '/checkin/personalize',
      '/checkin/passport',
      '/checkin/review',
      '/checkin/checkedin',
    ],
    error: '/error',
  },
  CHECKIN_KIOSK: {
    main: [
      '/checkin_kiosk/start',
      '/checkin_kiosk/contacts',
      // '/checkin_kiosk/image',
      '/checkin_kiosk/agree',
      '/checkin_kiosk/done',
    ],
    error: '/error',
  },
};

// Keep the same for now
FLOWS.BOOK_NAP = FLOWS.BOOK_NIGHT;
function FlowMgr(flowName) {
  function _getCurrentIndex(currentPath) {
    // don't make this generic first
    if (currentPath === '/booking/date-pick/edit') {
      currentPath = '/booking/date-pick';
    }
    return FLOWS[flowName].main.indexOf(currentPath);
  }
  return {
    findNext: function(currentPath) {
      const maxIndex = FLOWS[flowName].main.length;
      const nextIndex = _getCurrentIndex(currentPath) + 1;
      if (nextIndex > 0 && nextIndex <= maxIndex) {
        return FLOWS[flowName].main[nextIndex];
      }

      // TO DO: redirect to error page with more info is better
      return FLOWS[flowName].main[0];
    },
    findPrevious: function(currentPath) {
      const maxIndex = FLOWS[flowName].main.length;
      const nextIndex = _getCurrentIndex(currentPath) - 1;
      if (nextIndex > 0 && nextIndex <= maxIndex) {
        return FLOWS[flowName].main[nextIndex];
      }

      // TO DO: redirect to error page with more info is better
      return FLOWS[flowName].main[0];
    },
    // TO DO: create a function to check the index is inRange or not
    toStep: function(step) {
      if (step > 0) {
        return FLOWS[flowName].main[step];
      }
      return FLOWS[flowName].main[0];
    },
    getCurrentIndex: _getCurrentIndex,
  };
}

function FlowSrvc($location, $route, $rootScope, $window) {
  let _currentFlow = 'BOOK_NIGHT';
  let _isChanging = false;

  function toErrorPageIfInProgressButIdle() {
    if (!_.includes(['/booking/completed', '/checkin/checkedin'], $route.current.originalPath)) {
      error('session-expired');
    }
  }
  $rootScope.$on('$routeChangeSuccess', function() {
    _isChanging = false;
  });

  function next() {
    // should return if path already in transition
    // listen to $routeChangeSuccess & update state), ideally $locatin itself return promise
    // https://github.com/angular/angular.js/issues/7392
    // Potential issue: double click will skip pages
    if (_isChanging) {
      return;
    }
    _isChanging = true;
    const nextStepAtFlow = FlowMgr(_currentFlow).findNext($route.current.originalPath);
    if (nextStepAtFlow) {
      if ($window.analytics) {
        $window.analytics.page(nextStepAtFlow);
      }
      $location.path(nextStepAtFlow);
      // $window.scrollTo(0, 0);
    } else {
      entry();
    }
  }

  function skipSteps(skipSteps) {
    const _toStep = FlowMgr(_currentFlow).getCurrentIndex($route.current.originalPath) + 1 + skipSteps;
    return toStep(_toStep);
  }

  // a clone of next()
  function previous() {
    // should return if path already in transition
    // listen to $routeChangeSuccess & update state), ideally $locatin itself return promise
    // https://github.com/angular/angular.js/issues/7392
    // Potential issue: double click will skip pages
    if (_isChanging) {
      return;
    }
    _isChanging = true;
    const nextStepAtFlow = FlowMgr(_currentFlow).findPrevious($route.current.originalPath);
    if (nextStepAtFlow) {
      if ($window.analytics) {
        console.log($window.analytics);
        $window.analytics.page(nextStepAtFlow);
      }
      $location.path(nextStepAtFlow);
      // $window.scrollTo(0, 0);
    } else {
      entry();
    }
  }

  function entry(withoutHistory) {
    console.log('[FLOW] currentFlow', _currentFlow);
    let entryStep = FLOWS[_currentFlow].entry;
    if (!entryStep) {
      entryStep = FLOWS[_currentFlow].main[0];
    }

    if (withoutHistory) {
      $location.path(entryStep).replace();
    } else {
      $location.path(entryStep);
    }
  }
  function error(err, backUrl) {
    // set aside of passing err now
    let path = FLOWS[_currentFlow].error;
    if (err && backUrl) {
      path += '/' + err + '?backUrl=' + encodeURIComponent(backUrl);
    } else if (err) {
      path = path + '/' + err;
    }
    // $location.path(path).replace();
    $location.url(path);
    // $rootScope.$digest();
  }
  function toFlow(flowName) {
    _currentFlow = flowName;
  }

  function toStep(step) {
    // should return if path already in transition
    // listen to $routeChangeSuccess & update state), ideally $locatin itself return promise
    // https://github.com/angular/angular.js/issues/7392
    // Potential issue: double click will skip pages
    if (_isChanging) {
      return;
    }
    _isChanging = true;
    const nextStepAtFlow = FlowMgr(_currentFlow).toStep(step);
    if ($window.analytics) {
      $window.analytics.page(nextStepAtFlow);
    }

    $location.path(nextStepAtFlow);
    // $window.scrollTo(0, 0);
  }

  return {
    next: next,
    previous: previous,
    skipSteps: skipSteps,
    entry: entry,
    error: error,
    toFlow: toFlow,
    toStep: toStep,
    toErrorPageIfInProgressButIdle: toErrorPageIfInProgressButIdle,
    // hacky expose to get labels
    getCurrentIndex: function() {
      return FlowMgr(_currentFlow).getCurrentIndex($route.current.originalPath);
    },
    getCurrentFlowSteps: function() {
      return _.clone(FLOWS[_currentFlow].main);
    },
    getFlowSteps: function(flowName) {
      return _.clone(_.get(FLOWS, flowName + '.main', []));
    },
    NAME: _.mapValues(_.cloneDeep(FLOWS), function(v, k) {
      return k;
    }),
  };
}

FlowSrvc.$inject = ['$location', '$route', '$rootScope', '$window'];

module.exports = FlowSrvc;
