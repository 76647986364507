const template = require('./checkedin.html');
require('./checkedin.css');

const CheckedInCtrl = function($scope, $location, APISrvc, ReserveDetailsSrvc, CheckInSrvc, UserSrvc, config) {
  CheckInSrvc.redirectIfOutOfSession();
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  $scope.vm.resendQRCode = function() {
    APISrvc.sendQRCode($scope.vm.reserveDetails.reservation.id, $scope.vm.reserveDetails.guest.email);
  };

  $scope.vm.isShowSignUp = true;
  const user = UserSrvc.get();
  if (!_.isEmpty(_.get(user, 'profile'))) {
    $scope.vm.isShowSignUp = false;
  }
  $scope.vm.supportContact = {
    tel: config.SUPPORT_CONTACT.TEL.replace(' ', ''),
    email: config.SUPPORT_CONTACT.EMAIL_RESERVED.toLowerCase(),
  };

  $scope.$on('login.success', function() {
    $scope.vm.isShowSignUp = false;
  });
};

CheckedInCtrl.$inject = ['$scope', '$location', 'APISrvc', 'ReserveDetailsSrvc', 'CheckInSrvc', 'UserSrvc', 'config'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: CheckedInCtrl,
};
