const mapper = module.exports = {};
mapper.asGuest = function(exp3Profile) {
  // this should conforms to @Guest

  // auth0:male vs Male
  // got age_range but not actual dob

  return {
    firstName: exp3Profile.firstname,
    middleName: exp3Profile.middlename,
    lastName: exp3Profile.lastname,
    gender: exp3Profile.gender == 'Male'?'male': exp3Profile.gender == 'Female'? 'female': '',
    email: exp3Profile.email,
    phoneCode: exp3Profile.phonecode,
    phone: exp3Profile.telephone,
    dob_year: exp3Profile.dob_year,
    dob_month: exp3Profile.dob_month,
    dob_day: exp3Profile.dob_day,
    country: exp3Profile.country? exp3Profile.country:APP_CONFIG.OPERATIONS.DEFAULT_CUSTOMER_COUNTRY,
  };
};

mapper.asDBUser = function(user) {
  const profile = user.profile || {};
  const details = user.details || {};

  let gender = profile.gender;

  switch (details.gender) {
    case 'M':
    case 'm':
      gender = 'male';
      break;
    case 'F':
    case 'f':
      gender = 'female';
      break;
    default:
      if (!gender) {
        gender = 'other';
      }
  }
  return {
    firstName: profile.family_name || details.first_name,
    lastName: profile.given_name || details.last_name,
    gender,
    email: profile.email || details.email,
    dob: profile.birthdate || details.dob,
    country: profile.country || details.country,
    phone: profile.phone_number || details.phone,
    verified: _.get(details, 'email_verified') && _.get(details, 'details_verified'),
  };
};
