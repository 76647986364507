require('./login-button.css');
const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
const LoginCtrl = function($scope, $element, auth, $location, UserSrvc, store) {
  // TO DO proper chk against store
  const cachedState = {
    profile: store.get('cachedState'),
    expires_at: store.get('expires_at'),
  };

  const _valdiate = function() {
    return false;
  };

  const _inSession = _valdiate(cachedState);
  //
  function _updateProfile(profile, idToken) {
    UserSrvc.set({profile: profile, idToken: idToken});
    store.set('profile', profile);
    store.set('token', idToken);
    $scope.$root.$broadcast('login.success', profile);
    _inSession = true;
  }
  function login() {
    const options = {
      icon: EMPTY_IMAGE,
      showIcon: false,
      primaryColor: '#000000',
      popup: true,
      // theme: 'sleeep'
    };
    // empty callback required, handle by event instead
    return auth.signin(options,
        function(profile, idToken) {
          _updateProfile(profile, idToken);
        },
        function() {
        });
  }
  $element.bind('click', function() {
    login();
  });

  // quick hack to reuse login logic here
  $scope.$on('login.ask', function() {
    if (!_inSession) {
      login();
    }
  });
};
LoginCtrl.$inject = ['$scope', '$element', 'auth', '$location', 'UserSrvc', 'store'];
module.exports = {
  restrict: 'EA',
  bindings: {
  },
  controller: LoginCtrl,
};
