// separated as could be diff from reservations
const userMapper = require('../lib/mapper/user');
const schema = require('../lib/types/guest').schema;

const ajv = require('../lib/ajv');

/* global _*/
function UserSrvc($location, APISrvc) {
  let _user = {};
  return {
    get: function() {
      return _user;
    },
    set: function(userDetail) {
      _user = userDetail.users;
    },
    validate: function(user) {
      const mappedUser = (user) ? user : userMapper.asReserveDetailGuest(_user);

      return ajv.validate(schema, mappedUser);
    },
  };
}
UserSrvc.$inject = ['$location', 'APISrvc'];
module.exports = UserSrvc;
