function BookingFlowResolve(FlowSrvc, ReserveDetailsSrvc, UserSrvc) {
  const currentIndex = FlowSrvc.getCurrentIndex();
  const reserveDetails = ReserveDetailsSrvc.get();

  // to destructuring to validate instead of use ReservationSrvc.validate()
  // const guest = reserveDetails.guest;
  const reservation = reserveDetails.reservation;

  // const validatedGuest = UserSrvc.validate(guest);
  const validatedReservation = ReserveDetailsSrvc.validateReservation(reservation);
  // gtag('config', 'UA-57161274-1', {'page_path': APP_CONFIG.BOOKING_STEPS_URLS[currentIndex]}); // TODO: how to test this?

  // step 2 should validated reservation
  if (currentIndex === 2) {
    if (validatedReservation) {
      // this.next();
    } else {
      FlowSrvc.entry('withoutHistory');
      // Missing Reservation Details
      return Promise.reject();
    }
  }

  // step 3 should validated reservation, and guest
  if (currentIndex === 3 || currentIndex === 4) {
    // if guest and reservation details are all validated
    console.log(validatedReservation, '...');
    if (validatedReservation) {
      // to the book/confirm page
      // this.next();
    } else {
      FlowSrvc.entry('withoutHistory');
      // Guest and Reservation details are required, only one of them not empty.
      return Promise.reject();
    }
  }
}

BookingFlowResolve.$inject = ['FlowSrvc', 'ReserveDetailsSrvc', 'UserSrvc'];
module.exports = BookingFlowResolve;
