const template = require('./contacts.html');
require('./contacts.css');

const ContactsCtrl = function($scope, $location, FlowSrvc, ReserveDetailsSrvc, CheckInSrvc, ModalService) {
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  // force enter & override
  $scope.vm.email = '';
  $scope.vm.kioskSteps = APP_CONFIG.CHECKIN_KIOSK_STEPS_LABELS;
  $scope.vm.kioskStepsUrl = APP_CONFIG.CHECKIN_KIOSK_STEPS_URLS;

  console.log('contacts');
  $scope.vm.showVerifyModal = function() {
    document.querySelector('input[type="email"]').blur();
    ModalService.showModal({
      template: require('./email_verify_modal.html'),
      controller: ['$scope', 'close', function($scope, close) {
        $scope.close = close;
      }],
      scope: $scope,
      bodyClass: 'modal-open',
    });
  };
  $scope.vm.submit = function() {
    ReserveDetailsSrvc.update({
      guests: [{
        email: $scope.vm.email,
        emailAtBooking: $scope.vm.reserveDetails.guest.email,
      }],
    });

    FlowSrvc.next();
  };

  $scope.vm.enterPressed = function(e) {
    if ($scope.form.$error.email || $scope.form.$error.required) {
      // $scope.vm.emailErrorMsg = 'Email address is invalid.';
    } else {
      e.target.blur();
      $scope.vm.showVerifyModal();
    }
  };
};

ContactsCtrl.$inject = ['$scope', '$location', 'FlowSrvc', 'ReserveDetailsSrvc', 'CheckInSrvc', 'ModalService'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ContactsCtrl,
};
