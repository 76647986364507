const template = require('./completed.html');
require('./completed.css');
const CompletedCtrl = function($scope, APISrvc, ReserveDetailsSrvc, $timeout, $location) {
  const urlParams = _.pick($location.search(), ['oid']);
  $scope.vm.dimmed = false;
  console.log(`dimmed = ${$scope.vm.dimmed}`);
  if (urlParams.oid) {
    $scope.vm.dimmed = true;
    APISrvc.getOrderInfo(urlParams.oid).then((data) => {
      $scope.vm.dimmed = false;
      console.log(data);
      const obj = data.data.Item.complete_page_info;
      $scope.vm.reserveDetails.guest.firstName = obj.firstName;
      $scope.vm.reserveDetails.guest.middleName = obj.middleName;
      $scope.vm.reserveDetails.guest.lastName = obj.lastName;
      $scope.vm.reserveDetails.guest.email = obj.email;
      $scope.vm.reserveDetails.reservation.startDate = moment(obj.startDate);
      $scope.vm.reserveDetails.reservation.endDate = moment(obj.endDate);
    });
  }
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  // $scope.vm.checkinUrl = $scope.vm.reserveDetails.reservation.checkinUrl;
  // console.log('checkinUrl', $scope.vm.checkinUrl);
  //   $scope.vm.hideQrCodePromise = $timeout(function() {
  //     $scope.vm.qrCodeUrl = $scope.vm.reserveDetails.reservation.qrCodeUrl;
  //   }, 3000);
  //   $scope.vm.resendConfirm = function() {
  //     APISrvc.sendConfirm($scope.vm.reservation, $scope.vm.reservation.guestsByReservationId);
  //   };
  $scope.vm.supportContact = {
    email: APP_CONFIG.SUPPORT_CONTACT.EMAIL_RESERVED,
    tel: APP_CONFIG.SUPPORT_CONTACT.TEL,
  };
  $scope.vm.locationInfo = {
    access: APP_CONFIG.LOCATION_INFO.ACCESS_DIRECTIONS,
    address: APP_CONFIG.LOCATION_INFO.MAILING_ADDRESS,
    mapUrl: APP_CONFIG.LOCATION_INFO.MAP_URL,
    googleMapUrl: APP_CONFIG.LOCATION_INFO.GOOGLE_MAP_URL,
  };
  $scope.vm.marketingInfo = {
    igUrl: APP_CONFIG.MARKETING_INFO.INSTAGRAM_URL,
    fbUrl: APP_CONFIG.MARKETING_INFO.FACEBOOK_URL,
    websiteUrl: APP_CONFIG.MARKETING_INFO.LOCATION_PAGE_URL,
    greeting: APP_CONFIG.MARKETING_INFO.GREETING,
  };
};

CompletedCtrl.$inject = ['$scope', 'APISrvc', 'ReserveDetailsSrvc', '$timeout', '$location'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {},
  controller: CompletedCtrl,
};