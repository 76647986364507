require('./passport_upload.css');
const template = require('./passport_upload.html');

// FlowSrvc is unrelated to flow
const PassportUploadCtrl = ($scope, $location, APISrvc, ReserveDetailsSrvc, CheckInSrvc, FlowSrvc, ModalService) => {
  $scope.vm.checkinSteps = APP_CONFIG.CHECKIN_STEPS_LABELS;
  $scope.vm.checkinStepsUrl = APP_CONFIG.CHECKIN_STEPS_URLS;
  // dirty external state as we need to allow skip
  $scope.vm.isUploading = false;
  // if (!CheckInSrvc.isInSession()) {
  //   FlowSrvc.error();
  // }
  // CheckInSrvc.redirectIfOutOfSession();
  $scope.vm.uploadPassport = function(flow) {
    if (flow.files.length > 1) {
      flow.files[0].cancel();
    }
    if (flow.files.length > 0 && flow.files[0].error) {
      flow.files[0].retry();
    } else {
      $scope.vm.isUploading = true;
      flow.upload();
    }
  };

  $scope.vm.submit = function(flow) {
    const messageString = _.get(flow, 'files[0].msg');
    if (messageString) {
      const message = JSON.parse(messageString);
      console.log(message.passportUrl);
      ReserveDetailsSrvc.update({
        reservation: {
          passportUrl: message.passportUrl,
          isPassportUploaded: _.get(flow, 'files[0].status') === 'success',
        },
      });
    }

    FlowSrvc.next();
  };

  $scope.showPassportInfoModal = function() {
    ModalService.showModal({
      template: require('./passport_info_modal.html'),
      controller: ['$scope', 'close', function($scope, close) {
        $scope.close = close;
      }],
      bodyClass: 'modal-open',
    });
  };

  // $scope.status = null
  $scope.vm.uploadOptions = {
    target: APP_CONFIG.API_PATH + '/upload-passport',
    query: {
      reservation: JSON.stringify(ReserveDetailsSrvc.get().reservation),
    },
    testChunks: false,
  };
};

PassportUploadCtrl.$inject = [
  '$scope',
  '$location',
  'APISrvc',
  'ReserveDetailsSrvc',
  'CheckInSrvc',
  'FlowSrvc',
  'ModalService',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: PassportUploadCtrl,
};
