// const moment = require('moment-timezone');

// moment.tz.setDefault(APP_CONFIG.LOCATION_INFO.TIMEZONE);

function normalizeMoment(time) {
  return normalizeTime(parseInt(time.format('HHmm'), 10));
}
function normalizeTime(time) {
  time = parseInt(time, 10);
  return Math.floor(time / 100) + (time % 100) / 30 * 0.5;
}
function denormalizeTime(value) {
  return Math.floor(value) * 100 + (value % 1 * 60);
}
// function denormalizeAsMoment(value) {
//   var time24 = denormalizeTime(value);
//   return moment(time24, 'HHmm');
// }
function denormalizeAsMoment(time) {
  return moment({hour: Math.floor(time), minute: (time % 1 * 60)});
  // return moment(denormalizeTime(time), 'hmm');
}

// function HKTAsMidNightDateInUserTimeZone(momentDate) {
//   return new Date(momentDate.get('year'), momentDate.get('month'), momentDate.get('date'));
// }

function LocationTimeAsMidNightDateInUserTimeZone(momentDate) {
  // momentDate.add(APP_CONFIG.LOCATION_INFO.GMT, 'hours');

  return new Date(momentDate.get('year'), momentDate.get('month'), momentDate.get('date'));
}

module.exports = {
  normalizeTime: normalizeTime,
  denormalizeTime: denormalizeTime,
  normalizeMoment: normalizeMoment,
  denormalizeAsMoment: denormalizeAsMoment,
  LocationTimeAsMidNightDateInUserTimeZone: LocationTimeAsMidNightDateInUserTimeZone,
};
