// Factory for ajv
const Ajv = require('ajv');
// expose to global
const ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}
// require('ajv-keywords')(ajv);
// // TODO too tedius, but work
// var instanceofDefinition = require('ajv-keywords').get('instanceof').definition;
// instanceofDefinition.CONSTRUCTORS.moment = moment;

module.exports = ajv;
