/**
 * design for non-flat rate
 * Now coupled with stripe
*/

function calculateTotal(payment) {
  return _.reduce(payment.unitByKey, function(sum, v, k) {
    if (!payment.rateByKey[k]) {
      console.log('Error: Rate missing');
    }
    if (!_.isFinite(payment.rateByKey[k])) {
      console.log('Error: Rate incorrect');
      console.log(payment.rateByKey[k]);
    }
    sum += v * payment.rateByKey[k];
    return sum;
  }, 0);
}

// rateByKey, unitByKey
const Payment = function(payment) {
  const _payment = _.cloneDeep(payment);
  _payment.total = Math.round(calculateTotal(payment));
  Object.defineProperty(_payment, 'stripeTotal', {
    get: function() {
      return this.total * APP_CONFIG.OPERATIONS.STRIPE_MULTIPLIER;
    },
    enumerable: true,
  });
  return _payment;
};

const schema = {
  type: 'object',
  properties: {
    rateByKey: {
      type: 'object',
      properties: {
        rate: {
          type: 'number',
        },
        key: {
          type: 'string',
        },
      },
    },
    unitByKey: {
      type: 'object',
      properties: {
        unit: {
          type: 'number',
        },
        key: {
          type: 'string',
        },
      },
    },
    stripeToken: {
      type: 'object',
    },
    // for recon purpose
    total: {
      type: 'number',
    },
  },
  required: ['rateByKey', 'unitByKey', 'total'],
};

module.exports = {
  schema: schema,
  type: Payment,
};
