// var tokenUtil = require('../lib/token.js');
const Promise = require('bluebird');
// var ReserveDetails = require('../lib/types/reserve-details');
function CheckInSrvc($location, store, ReserveDetailsSrvc, APISrvc, $firebaseArray) {
  // inefficient to check every time
  // first in only and set after check-in
  let inSession = false;

  function isInSession() {
    return inSession;
  }
  function redirect() {
    $location.path('/');
  }

  function redirectIfOutOfSession() {
    if (!inSession) {
      console.log('Customer is out of Session');
      redirect();
    }
  }

  function _getCheckinPromise(isKioskMode, otaId, cloudbedsId, tokenString) {
    // not for cbId already (Website)
    // TO DO make it fast
    if (otaId && isKioskMode) {
      const ref = global.firebase.database().ref('id-mapping')
          .orderByChild('source-id')
          .equalTo(otaId);

      return $firebaseArray(ref)
          .$loaded()
          .then(function(ids) {
            console.log('ids from firebase');
            console.log('ids');
            const record = _.first(ids);
            const cloudbedsId = _.get(record, 'cb-id');
            if (cloudbedsId) {
              return APISrvc.validateCheckInSessionOta(cloudbedsId);
            }
            return Promise.any([{isValid: false}]);
          });
    }

    return cloudbedsId ? APISrvc.validateCheckInSessionOta(cloudbedsId) :
    APISrvc.validateCheckInSession(tokenString);
  }

  // only particular view can provide token
  // TO DO refactor the isKiosk flag
  // TO DO rename validateCheckInSessionOta to be validateCheckInSessionByCB
  function isValidCheckInSession(isKioskMode) {
    const _IsKioskMode = store.get('isKioskMode') || isKioskMode;
    const tokenString = $location.search().checkintoken;
    const otaId = $location.search().otaId;
    const cloudbedsId = $location.search().cloudbedsId;

    // if (CHECKIN_ALWAYS_VALIDATE === 'true') {
    //   inSession = true;
    //   var details = new ReserveDetails.type({source: '', bookingType: 'night'}, {});
    //   return Promise.any([{isValid: true, details: details}]);
    // }

    // For OTA,
    // ignore validity, always allow recheckin

    return _getCheckinPromise(_IsKioskMode, otaId, cloudbedsId, tokenString)
        .then(function(res) {
          inSession = true;
          console.log(res);
          if (_.get(res, 'data.isValid') && cloudbedsId && !_IsKioskMode && _.get(res, 'data.details.reservation')) {
            res.data.details.reservation.source = 'OTA';
          }

          return res.data;
        });
  }
  return {
    isValidCheckInSession: isValidCheckInSession,
    isInSession: isInSession,
    redirectIfOutOfSession: redirectIfOutOfSession,
    redirect: redirect,
  };
}

CheckInSrvc.$inject = ['$location', 'store', 'ReserveDetailsSrvc', 'APISrvc', '$firebaseArray'];
module.exports = CheckInSrvc;
