const template = require('./flow-progress-indicator.html');
// require('./progress_indicator.css');
const FlowProgressIndicatorCtrl = function($scope, FlowSrvc) {
  // FlowSrvc.
  $scope.range = function(n) {
    return new Array(n);
  };

  $scope.flowSteps = FlowSrvc.getCurrentFlowSteps();
  $scope.flowSteps.shift();
  $scope.flowSteps.pop();
  // magic slice /checkin & /checkedin in ui
  $scope.currentFlowIndex = FlowSrvc.getCurrentIndex();
  // hardcoded for now
  $scope.stepLabels = APP_CONFIG.CHECKIN_STEPS_LABELS;
  if ($scope.flowSteps.length === 3 && $scope.stepLabels.length !== 3) {
    $scope.stepLabels.shift();
  }

  console.log('flowSteps');
  console.log($scope.flowSteps);
};

// to workaround the bad design,
// we pass in and assume the index & assume index is the same as what in flow
// in future extract the flow & labels into same config doc
FlowProgressIndicatorCtrl.$inject = ['$scope', 'FlowSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: FlowProgressIndicatorCtrl,
};
