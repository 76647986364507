
const template = require('./confirm.html');
require('./confirm.css');
// input backurl & input message for kiosk
const PaymeCtrl = function($scope, $routeParams, $location, APISrvc) {
  $scope.vm.dimmed = true;
  const urlParams = _.pick($location.search(), ['oid']);
  APISrvc.getOrderInfo(urlParams.oid).then((data) => {
    $scope.vm.dimmed = false;
    console.log(data);
    const obj = data.data.Item.complete_page_info;
    $scope.vm.firstName = obj.firstName;
    $scope.vm.middleName = obj.middleName;
    $scope.vm.lastName = obj.lastName;
    $scope.vm.paymentRequestId = obj.paymentRequestId;
  });
  $scope.vm.supportContact = {
    email: APP_CONFIG.SUPPORT_CONTACT.EMAIL,
    tel: APP_CONFIG.SUPPORT_CONTACT.TEL,
  };
};

PaymeCtrl.$inject = ['$scope', '$routeParams', '$location', 'APISrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  controller: PaymeCtrl,
};
