require('./personalize.css');
const template = require('./personalize.html');
const PERSONALIZATION_OPTIONS = require('../../../lib/config/personalizations.json');

// validation handled by BE
// TO DO failure mesasge after review

// Decouple label (presentation), values(used in DB)
// <= i18n is easier

const DEFAULT_PERSONALIZATION = _.map(PERSONALIZATION_OPTIONS, function(option) {
  return _.extend({}, _.pick(option, ['label', 'type', 'values']), {value: option.values[option.default].value});
});
console.log(DEFAULT_PERSONALIZATION);

const PersonalizeCtrl = function($scope, FlowSrvc, APISrvc, ReserveDetailsSrvc, CheckInSrvc, UserSrvc, ModalService) {
  $scope.vm.checkinSteps = APP_CONFIG.CHECKIN_STEPS_LABELS;
  $scope.vm.checkinStepsUrl = APP_CONFIG.CHECKIN_STEPS_URLS;
  $scope.vm.user = UserSrvc.get();

  const reserveDetails = ReserveDetailsSrvc.get();
  $scope.personalization = DEFAULT_PERSONALIZATION;
  if (reserveDetails.reservation.preferences) {
    reserveDetails.reservation.preferences.forEach(function(preference) {
      _.extend(_.find($scope.personalization, {label: preference.label}), {value: preference.value});
    });
  }
  // if (!CheckInSrvc.isInSession()) {
  //   FlowSrvc.error();
  // }
  // CheckInSrvc.redirectIfOutOfSession();
  $scope.vm.isPersonalizeEnabled = false; // requires login
  function enablePersonalizeIfLoggedIn(user) {
    if (user.profile) {
      $scope.vm.isPersonalizeEnabled = true;
    }
  }

  $scope.$watchCollection('vm.user', function(newV) {
    let preferences = _.get($scope.vm.user, 'reservations.preferences', false);

    // set preferences while preferences loaded & not empty
    if (preferences) {
      // console.log('---- personalize', $scope.personalization);
      preferences = _.filter(preferences, function(preference) {
        return _.includes(_.map($scope.personalization, 'label'), preference.label);
      });
      _.merge($scope.personalization, preferences);
    }
  });

  // CheckInSrvc.redirectIfOutOfSession()
  // if (reserveDetails.bookingType === 'hour') {
  //   $scope.$root.$broadcast('login.ask');
  // } else {
  //   // never ask for login for 20H now
  //
  // }

  enablePersonalizeIfLoggedIn($scope.vm.user);
  $scope.$on('login.success', function() {
    enablePersonalizeIfLoggedIn($scope.vm.user);
  });

  function next() {
    // always use precheckin flow
    // bad hack
    if (_.first(FlowSrvc.getCurrentFlowSteps()) === '/booking') {
      FlowSrvc.toFlow('PRECHECKIN_NIGHT');
    }
    FlowSrvc.next();
  }

  // $scope.vm.addExtra = function(index) {
  //   var extra = angular.extend({}, $scope.personalization[index]); // clone
  //   $scope.personalization.splice(index, 0, extra);
  // };

  $scope.vm.submit = function() {
    reserveDetails.reservation.preferences = $scope.personalization
        .map(function(preference) {
          return _.pick(preference, ['label', 'value']);
        });
    ReserveDetailsSrvc.set(reserveDetails);
    next();
  };

  $scope.vm.skip = function() {
    next();
  };

  $scope.showPersonalizationInfoModal = function() {
    ModalService.showModal({
      template: require('./personalization_info_modal.html'),
      controller: ['$scope', 'close', function($scope, close) {
        $scope.close = close;
      }],
      bodyClass: 'modal-open',
    });
  };
};

PersonalizeCtrl.$inject = [
  '$scope',
  'FlowSrvc',
  'APISrvc',
  'ReserveDetailsSrvc',
  'CheckInSrvc',
  'UserSrvc',
  'ModalService',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: PersonalizeCtrl,
};
