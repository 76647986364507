if (!global._) {
  // don't override
  global._ = require('lodash');
}
global.jquery$ = require('jquery');
console.log('_.VERSION ' + _.VERSION);
global.APP_CONFIG = require('./config/index.js');
global.moment = require('moment-timezone');
console.log(moment.tz.guess(), moment().format('ZZ'));
moment.tz.setDefault(moment.tz.guess());
console.log(moment().format(), 'setDefault');
// Decided to use bluebird globally but not es6-promise, as
// 1) we find merits in .spread etc. http://bluebirdjs.com/docs/api/spread.html
// 2) loading both es-promise & bluebird in different files is chaotic
// To check on speed, and watch out for this:
// https://github.com/petkaantonov/bluebird/issues/1026
global.Promise = require('bluebird');

// global.winston = require('winston')
// winston.level = 'debug'
