/**
* Refer to types/reserve-detail.js for overall type design
*/

// TO DO: change guest to user, or create another type(user.js)
// because those attributes are not the same
const factory = function(guest) {
  // TO DO keep dob as date-time or use moment, then  deserlize here?
  // Business logic validations not relying on type
  return _.merge({}, {
    firstName: '',
    middleName: '',
    lastName: '',
    country: '',
    email: '',
    gender: '',
    dob: '',
    phoneCode: '',
  }, guest);
};

const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    pmsGuestId: {
      type: 'string',
    },
    firstName: {
      type: 'string',
    },
    middleName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    email: {
      type: 'string',
      format: 'email',
    },
    country: {
      type: 'string',
    },
    gender: {
      type: 'string',
    },
    dob: {
      type: 'string',
      // format: 'date-time', datatype of dob in db is timestamp with timezone
    },
    phoneCode: {
      type: 'string',
    },
    phone: {
      type: 'string',
    },
  },
  required: ['email', 'firstName', 'lastName', 'country', 'dob', 'gender', 'phoneCode', 'phone'],
};

module.exports = {
  schema: schema,
  factory: factory,
};
