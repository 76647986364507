const template = require('./start.html');
require('./start.css');
require('./door_opening_modal.css');
const EXP = 'SLEEEP Website';
const StartCtrl = function($scope, $location, FlowSrvc, ModalService, $interval, $timeout) {
  // $scope.$on('login.success', function(data) {
  //   $scope.vm.isShowSignUp = false;
  // });
  $scope.$root.isKioskWithId = false;
  $scope.$root.isKioskMode = true;
  $scope.$root.isDoorModalOpened = false;
  $scope.vm.formSubmitted = false;
  $scope.vm.redirect_time = 45000;
  let firstRead = false;
  // won't be collected
  $scope.$root.kioskDoorListener = global.firebase.database().ref('doorLastOpened')
      .on('value', function() {
        if (!firstRead) {
          // hack
          firstRead = true;
          return;
        }
        if ($scope.$root.isDoorModalOpened) {
          return;
        }
        ModalService.showModal({
          template: require('./door_opening_modal.html'),
          controller: ['$scope', 'close', function($scope, close) {
            $scope.close = function() {
              console.log('close');
              $scope.$root.isDoorModalOpened = false;
              close();
            };
            setTimeout(function() {
              $scope.close();
            }, 10000);
            $scope.$root.isDoorModalOpened = true;
          }],
          bodyClass: 'modal-open',
        });
      });

  FlowSrvc.toFlow('CHECKIN_KIOSK');
  // TO DO sync with config, also deocuple display vs code
  $scope.vm.SOURCES = ['Agoda', 'Airbnb', 'Booking.com', 'Expedia', 'Hotels.com', 'Klook', EXP];
  $scope.vm.activeSourceIdx = null;
  $scope.vm.isDEV = process.env.NODE_ENV === 'DEV';
  $scope.vm.kioskSteps = APP_CONFIG.CHECKIN_KIOSK_STEPS_LABELS;
  $scope.vm.kioskStepsUrl = APP_CONFIG.CHECKIN_KIOSK_STEPS_URLS;
  $scope.vm.sourceFormat = '#####';

  $scope.vm.pickSource = function(idx) {
    let format = '';
    switch (idx) {
      case 0: // Agoda
        format = '#########';
        break;
      case 1: // Airbnb
        format = '##########';
        break;
      case 2: // Booking.com
        format = '##########';
        break;
      case 3: // Expedia
        format = '#########';
        break;
      case 4: // Hotels.com (same as Expedia)
        format = '#########';
        break;
      case 5: // Klook
        format = '########';
        break;
      case 6: // EXP
        format = '##########';
        break;
      default:
        console.log('invalid source ID');
    }
    $scope.vm.sourceFormat = format;
    $scope.vm.activeSourceIdx = idx;
    document.getElementById('reservation-num-input').focus();
  };

  $scope.vm.submit = function() {
    const source = $scope.vm.SOURCES[$scope.vm.activeSourceIdx];
    if (source === EXP) {
      $location.search({cloudbedsId: $scope.vm.reservationId});
    } else {
      $location.search({otaId: $scope.vm.reservationId, source: source});
    }
    FlowSrvc.next();
  };

  $scope.vm.toScanQRcodePage = function() {
    $scope.vm.haveKey = true;
    let _redirect_time = _.clone($scope.vm.redirect_time);
    // set timeout
    const notice = $interval(function() {
      _redirect_time -= 1000;
      const time_left = _redirect_time / 1000;
      $scope.vm.timer = `${time_left}`;
    }, 1000);

    $timeout(function() {
      console.log('$timeout');
      $interval.cancel(notice);
      // clear all params
      $location.search({});
      $scope.vm.restart();
      _.unset($scope.vm, 'timer');
    }, _redirect_time);
  };

  $scope.vm.showWifiModal = function() {
    ModalService.showModal({
      template: require('./wifi_modal.html'),
      controller: ['$scope', 'close', function($scope, close) {
        $scope.close = close;
        $scope.wifiUserName = APP_CONFIG.WIFI.USERNAME;
        $scope.wifiPassword = APP_CONFIG.WIFI.PASSWORD;
      }],
      bodyClass: 'modal-open',
    });
  };
  $scope.vm.restart = function() {
    $scope.vm.checkin = false;
    $scope.vm.haveKey = false;
    $scope.vm.manualCheckin = false;
    $scope.vm.activeSourceIdx = null;
    $scope.vm.reservationId = '';
    $scope.vm.formSubmitted = false;
    window.scrollTo(0, 0);
  };

  $scope.vm.enterPressedValidate = function() {
    if (($scope.vm.reservationId || '').length >= $scope.vm.sourceFormat.length) {
      // in order to trigger mobile keyboard to submit form
      $scope.vm.formSubmitted = true;
      $scope.vm.submit();
      return true;
    }
    $scope.vm.reservationIdErrorMsg = 'Reservation ID is too short.';
    return false;
  };
};

StartCtrl.$inject = ['$scope', '$location', 'FlowSrvc', 'ModalService', '$interval', '$timeout'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: StartCtrl,
};
