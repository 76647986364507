/**
* Refer to types/reserve-detail.js for overall type design
*/

const factory = function(discount) {
  const _discount = _.cloneDeep(discount);
  if (_.isEmpty(_discount)) {
    return;
  }

  if (_discount.roomType) {
    _discount.roomType = _.split(_discount.roomType, ',');
  }

  if (_discount.startDate) {
    _discount.startDate = moment(_discount.startDate);
  }

  if (_discount.expiryDate) {
    _discount.expiryDate = moment(_discount.expiryDate, ',');
  }

  if (_discount.couponAsDiscount) {
    const pickFromCoupon = _.pick(
        _discount.coupon,
        [
          'startDate', 'expiryDate', 'email', 'roomType', 'notes', 'discountType', 'discountValue', 'isDisabled',
        ]
    );
    _.merge(_discount, pickFromCoupon);
  }

  return _discount;
};

/*
* Discount Example, no db/excel currently
* {
*   startDate: '2017-05-24',
*   expiryDate: '2017-05-24',
*   startTime: 0,
*   endTime: 2300,
*   email: 'timching95@gmail.com',
*   roomType: '45m,1h,2h,',
*   notes: '',
*   discountType: 'relative|absolute',
*   discountValue: '0.65',
*   enabled: true,
*   couponAsDiscount: true,
*   coupon: CouponObject
* }
*/

const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    startDate: {
      type: 'object',
      instanceof: 'moment',
    },
    expiryDate: {
      type: 'object',
      instanceof: 'moment',
    },
    code: {
      type: 'string',
    },
    codeLowercase: {
      type: 'string',
    },
    email: {
      type: 'string',
      format: 'email',
    },
    roomType: {
      type: 'array',
    },
    notes: {
      type: 'string',
    },
    useRemaining: {
      type: 'nunmber',
    },
    timesUsed: {
      type: 'nunmber',
    },
    discountType: {
      type: 'string',
    },
    discountValue: {
      type: 'number',
    },
    disabled: {
      type: 'boolean',
    },
  },
  required: ['code', 'codeLowercase', 'useRemaining', 'discountType', 'discountValue', 'disabled'],
};

module.exports = {
  schema: schema,
  factory: factory,
};
