const template = require('./menu.html');

require('./menu.css');

const MenuCtrl = function($scope, $http, ReserveDetailsSrvc) {
  $scope.vm.user = ReserveDetailsSrvc.get().guest;
};
MenuCtrl.$inject = ['$scope', '$http', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: MenuCtrl,
};
