const ReserveDetails = require('../../lib/types/reserve-details');

// returns instance of ReserveDetailsSrvc with cache handled
function ReserveDetailsResolve(ReserveDetailsSrvc, storage) {
  if (!ReserveDetailsSrvc.isInitialized()) {
    // Avoid extra save-load cycle transition between routes
    const _cache = storage.get(APP_CONFIG.RESERVE_DETAILS_CACHE_KEY);
    if (_cache) {
      // struggle should we type check here as guests details not completely
      // var isValid = ajv.validate(ReserveDetails.schema, _cache)
      // if (isValid) {
      const details = new ReserveDetails.type(_cache.reservation, _cache.guest);
      // At book stage (but not checkin), discard cache if startDate / endDate passed
      const now = moment();
      if (details.endDate < now || details.startDate < now) {
        return;
      }
      ReserveDetailsSrvc.set(_cache);

      // } else {
      //   console.log('Errors in type')
      //   console.log(ajv.errors)
      // }
    }
  }
}

ReserveDetailsResolve.$inject = ['ReserveDetailsSrvc', 'store'];
module.exports = ReserveDetailsResolve;
