function idleProviderConfig(IdleProvider) {
  // configure Idle settings
  if (process.env.NODE_ENV === 'DEV') {
    return;
  }
  IdleProvider.idle(5 * 60); // in seconds
  IdleProvider.timeout(10); // in seconds
  // KeepaliveProvider.interval(2); // in seconds
}

idleProviderConfig.$inject = ['IdleProvider'];
module.exports = idleProviderConfig;
