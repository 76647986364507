const template = require('./select-room.html');
require('./select-room.css');
const DatePickCtrl = (
    $scope,
    $location,
    ReserveDetailsSrvc,
    RoomAvailabilitySrvc,
    PaymentSrvc,
    FlowSrvc
) => {
  $scope.vm.currency = APP_CONFIG.OPERATIONS.CURRENCY;
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  $scope.vm.startUrl = APP_CONFIG.SITE_URL;
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gmt = APP_CONFIG.LOCATION_INFO.GMT;
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  console.log($scope.vm.reserveDetails, '0');
  $scope.vm.startDate = moment($scope.vm.reserveDetails.reservation.startDate).toDate();
  $scope.vm.endDate = moment($scope.vm.reserveDetails.reservation.endDate).toDate();
  const duration = $scope.vm.reserveDetails.reservation.duration;
  const man = $scope.vm.reserveDetails.reservation.male;
  const female = $scope.vm.reserveDetails.reservation.female;
  $scope.vm.totalGuest = man + female == 0?1:(man + female);
  console.log($scope.vm.totalGuest, '$scope.vm.totalGuest');
  console.log(duration);
  // $scope.vm.roomDetails = [];
  $scope.vm.loading = true;
  $scope.vm.roomDetails = filterAvailableRoomType(RoomAvailabilitySrvc, duration, man, female, $scope.vm.startDate, $scope.vm.endDate, $scope.vm.roomDetails);
  $scope.vm.checkPrivateRoom = checkPrivateRoom;
  // $scope.vm.roomDetails = availableRoomType;
  $scope.vm.ceil = ceil;
  $scope.submit = function() {
    console.log('submit');
    console.log($scope.vm.selectedRoom);
    console.log($scope.vm.reserveDetails, '1');
    const payment = updateNightRate(PaymentSrvc, $scope.vm.selectedRoom, (man+female), checkPrivateRoom($scope.vm.selectedRoom.roomTypeNameShort));
    console.log(payment);
    $scope.vm.reserveDetails = _.merge(
        $scope.vm.reserveDetails,
        {
          reservation: _.pick($scope.vm.selectedRoom, ['roomTypeID', 'spaceType']),
          payment: payment,
        }
    );
    console.log($scope.vm.reserveDetails);
    ReserveDetailsSrvc.set($scope.vm.reserveDetails);
    console.log(ReserveDetailsSrvc.get());
    FlowSrvc.next();
  };
};
DatePickCtrl.$inject = [
  '$scope',
  '$location',
  'ReserveDetailsSrvc',
  'RoomAvailabilitySrvc',
  'PaymentSrvc',
  'FlowSrvc',
];
// $scope.vm.timeRange.end =moment();
module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    onsubmit: '&',
    new: '@',
  },
  controller: DatePickCtrl,
};
function checkPrivateRoom(key) {
  const privateRoomFlag = APP_CONFIG.OPERATIONS.PRIVATE_ROOM_FLAG;
  if (_.includes(privateRoomFlag, key)) {
    return true;
  } else {
    return false;
  }
}

function updateNightRate(PaymentSrvc, roomType, totalunit, privateRoom) {
  console.log('update night rate.');
  if (privateRoom) {
    totalunit = 1;
  }
  const payment = PaymentSrvc.createPaymentNight(roomType.roomRateDetailed, totalunit);
  console.log(payment);
  return payment;
}

function ceil(cal) {
  return Math.ceil(cal);
}

function filterAvailableRoomType(RoomAvailabilitySrvc, duration, man, female, startDate, endDate) {
  const filteredRoom = [];
  const cloudbedsRoomType = RoomAvailabilitySrvc.getCloudbedsRoomType();
  console.log(cloudbedsRoomType);
  console.log('cloudbedsRoomType');
  // const nightOptions = RoomAvailabilitySrvc.getNightOptions();
  const availableRoomType = cloudbedsRoomType.filter(function(o) {
    // console.log(nightOptions);
    // const index = _.findIndex(nightOptions, ['duration', duration]);
    // party booking or room available lower than total people
    const availableMatch = o.roomsAvailable >= (man+female) || o.adultsIncluded >= (man+female);
    console.log(o.roomsAvailable + 'o.roomsAvailable' + (man+female) + 'man+female' + o.adultsIncluded);
    console.log(o.roomTypeNameShort, duration);
    console.log(o.roomTypeNameShort.search(duration));
    return availableMatch && o.roomTypeNameShort == duration;
  });
  const roomRateStart = moment(startDate);
  const roomRateEnd = moment(endDate);
  _(availableRoomType).forEach(function(value) {
    console.log(value);
    const roomRateDetailed = [];
    RoomAvailabilitySrvc.getDurationDetail({
      'duration': duration,
      'start': roomRateStart.format('YYYY-MM-DD'),
      'end': roomRateEnd.format('YYYY-MM-DD'),
      'location': APP_CONFIG.LOCATION_ID,
      'spacetype': value.spaceTypeId,
    })
    .then(_.property('data'))
    .then(function(priceDetail) {
      let totalRate = 0;
      console.log('check point');
      console.log(priceDetail);
      _.forEach(priceDetail.spaceprices, (x) => {
        roomRateDetailed.push({'date': x.day, rate: parseInt(x.price), adults: 0, kids: 0});
        totalRate += parseInt(x.price);
        value.roomRateDetailed = roomRateDetailed;
        value.roomRate = totalRate;
        filteredRoom.push(value);
        console.log(filteredRoom);
        // $scope.vm.loading = false;
      });
    });
    // while (roomRateStart.isBefore(roomRateEnd)) {
    //   const localeWeek = Number.parseInt(roomRateStart.format('d'));
    //   const filter = _.filter(value.weeklyPrice, function(o) {
    //       return o.week == localeWeek;
    //     });
    //   console.log(filter);
    //   console.log('filter');
    //   roomRateDetailed.push({'date': roomRateStart.format('YYYY-MM-DD'), rate: _.get(filter, ['0', 'price']), adults: 0, kids: 0});
    //   roomRateStart.add(1, 'days');
    //   totalRate += _.get(filter, ['0', 'price']);
    //   value.roomRateDetailed = roomRateDetailed;
    //   value.roomRate = totalRate;
    // }
    console.log(availableRoomType);
  });
//   if (APP_CONFIG.OPERATIONS.EXIST_FEMALE_ONLY_SLPER) {
//     const femaleFlag = APP_CONFIG.OPERATIONS.FEMALE_ONLY_FLAG;
//     if (man >= 1) {
//       availableRoomType = availableRoomType.filter(function(o) {
//         console.log(o.roomTypeNameShort.search(femaleFlag), o.roomTypeNameShort, femaleFlag);
//         return o.roomTypeNameShort.search(femaleFlag) == -1;
//       });
//     }
//   }
  console.log(availableRoomType);
  return filteredRoom;
}

