require('./passport_upload.css');
// var template = require('./passport_upload.html');

const TokenCheckCtrl = function($scope, $location, CheckInSrvc) {
  if (!CheckInSrvc.isInSession()) {
    CheckInSrvc.redirect();
  }
  // async. better use resolve?
};

TokenCheckCtrl.$inject = ['$scope', '$location', 'CheckInSrvc'];

module.exports = TokenCheckCtrl;
