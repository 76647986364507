const template = require('./progress_indicator.html');
require('./progress_indicator.css');
const ProgressIndicatorCtrl = function($scope) {
  $scope.range = function(n) {
    return new Array(n);
  };
  $scope.vm.goto = function($index) {
    return $scope.vm.urls[$index];
  };
};

ProgressIndicatorCtrl.$inject = ['$scope'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    steps: '=',
    urls: '=',
    current: '=',
    labels: '=',
  },
  controller: ProgressIndicatorCtrl,
};
