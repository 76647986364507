
const template = require('./error.html');
require('./error.css');
// input backurl & input message for kiosk
const ErrorCtrl = function($scope, $routeParams, $location, APISrvc) {
  $scope.vm.errorMessage = $routeParams.message;
  $scope.vm.dimmed = false;
  if ($scope.vm.errorMessage == 'payme') {
    const urlParams = _.pick($location.search(), ['oid']);
    $scope.vm.dimmed = true;
    APISrvc.getOrderInfo(urlParams.oid).then((data) => {
      $scope.vm.dimmed = false;
      console.log(data);
      const obj = data.data.Item.complete_page_info;
      $scope.vm.firstName = obj.firstName;
      $scope.vm.middleName = obj.middleName;
      $scope.vm.lastName = obj.lastName;
      $scope.vm.paymentRequestId = obj.paymentRequestId;
    });
  }
  $scope.vm.refresh = function() {
    if ($routeParams.backUrl) {
      $location.search('backUrl', null);
      $location.path($routeParams.backUrl);
      // window.history.back();
    } else {
      window.location.href = '/booking';
    }
  };
  $scope.vm.supportContact = {
    email: APP_CONFIG.SUPPORT_CONTACT.EMAIL,
    tel: APP_CONFIG.SUPPORT_CONTACT.TEL,
  };
};

ErrorCtrl.$inject = ['$scope', '$routeParams', '$location', 'APISrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  controller: ErrorCtrl,
};
