/**
* Delegation to be more cohesive
* resposible to update rate / payment from reserve details
* - bookingType
* - bookingDate
* - formatting requirement
* however these are not available in @reserveDetails during datepick
*/
// const cbModelDate = require('../lib/cloudbeds/model/date');
// const cbModelRoomType = require('../lib/cloudbeds/model/room_type.js');

const Payment = require('../lib/types/payment');
const PaymentSrvc = function() {
  function createPaymentNight(_availabilityByDate, totalunit) {
    return _getPayment(_deriveRateByNight(_availabilityByDate), totalunit);
  }
  function createPaymentHour(amountPerHourPlan) {
    return _getPayment({
      hour: amountPerHourPlan,
    });
  }
  return {
    createPaymentNight: createPaymentNight,
    createPaymentHour: createPaymentHour,
    listRateByDate: listRateByDate,
  };
};
// only if format is consistent
function listRateByDate(rateByKey) {
  return _.mapKeys(rateByKey, function(cbDate) {
    return moment(cbDate).format('YYYY.MM.DD (ddd)');
  });
}
function _deriveRateByNight(_availabilityByDate) {
  _availabilityByDate = _.keyBy(_availabilityByDate, 'date');
  console.log('in payment :', _availabilityByDate);
  console.log(_.mapValues(_availabilityByDate, 'rate'));
  return _.mapValues(_availabilityByDate, 'rate');
}

// TO DO workaround as backend don't load rate now
// TO DO assumed price no change when load from cache
function _getPayment(rateByKey, totalunit) {
  const unitByKey = _.mapValues(rateByKey, function() {
    return totalunit || 1;
  });
  return new Payment.type({
    rateByKey: rateByKey,
    unitByKey: unitByKey,
  });
}

PaymentSrvc.$inject = [];
module.exports = PaymentSrvc;
