function PayMeSrvc($http, config) {
  /**
   * startDate
   * endDate
   * roomType
   * guestCount
   **/

  const createPaymentRequest = function(details) {
    return $http.post(
      'https://uehara.serverless.exp.is/payme/paymentrequests',
      details);
  };

  const checkPaymentStatus = function(paymentRequestId) {
    return $http.get(
      `https://uehara.serverless.exp.is/payme/paymentrequests/${paymentRequestId}`);
  };

  return {
    createPaymentRequest: createPaymentRequest,
    checkPaymentStatus: checkPaymentStatus,
  };
}

PayMeSrvc.$inject = ['$http', 'config'];

module.exports = PayMeSrvc;