// var _ = require('lodash');
const moment = require('moment');
const config = module.exports = {};

// timeRangeStart as a moment object
config.isHoursOptionAllowed = function(hourCount, timeRangeStart) {
  if (!timeRangeStart) {
    return true;
  }
  const timeRangeStartMoment = moment(timeRangeStart);

  const hour = timeRangeStartMoment.hour();
  // 1 or 0
  const halfHourCount = Math.floor(timeRangeStartMoment.minute() / 30);
  const effectiveTime = hour * 100 + halfHourCount * 30;
  return _isHoursOptionAllowed(hourCount, effectiveTime);
};

// TO DO: Tie these variables to firebase
// var 1_start, 1_end,
//     2_start, 2_end,
//     4_start, 4_end,
//     8_start, 8_end,
//     12_start, 12_end

// Modify static rules here. Effective when UI redeployed
function _isHoursOptionAllowed(hourCount, effectiveTime) {
  if (hourCount === 0.75) {
    return effectiveTime >= 900 && effectiveTime < 2100;
  } else if (hourCount === 1) {
    return effectiveTime >= 900 && effectiveTime < 2000;
  } else if (hourCount === 2) {
    return effectiveTime >= 900 && effectiveTime < 1930;
  } else if (hourCount === 4) {
    return effectiveTime >= 900 && effectiveTime < 1730;
  } else if (hourCount === 8) {
    return (effectiveTime >= 900 && effectiveTime < 1330) ||
    (effectiveTime >= 2100 && effectiveTime < 2400);
  } else if (hourCount === 12) {
    return (effectiveTime >= 2000 && effectiveTime < 2400);
  }
  // default yes, handling init state (hourCount is 0 / null)
  return true;
}
