/* global _*/
function RoomAvailabilitySrvc($http, config) {
  /**
  * startDate
  * endDate
  * roomType
  * guestCount
  **/
  let cloudbedsRoomType = [];
  let nightOptions = [];
  const setNightOptions = function(data) {
    nightOptions = data;
  };
  const getNightOptions = function() {
    console.log(nightOptions);
    return nightOptions;
  };
  const setCloudbedsRoomType = function(data) {
    cloudbedsRoomType = data;
  };
  const getCloudbedsRoomType = function() {
    console.log(cloudbedsRoomType);
    return cloudbedsRoomType;
  };
//   const checkAvailability = function(details) {
//     return $http.post('config.API_PATH + '/check-availability, details);
//   };
  const checkAvailabeRoomType = function(details) {
    return $http.post(
        APP_CONFIG.LAMBDA.CHECK_AVAILABILITY_ROOM_TYPE,
        details);
  };
  const getGallery = function(locatioId) {
    return $http.get(
        'https://os.exp.is/api/sitegallerys/' + locatioId);
  };
  const checkPmsRoomType = function(locationId) {
    return $http.get(
        `https://os.exp.is/api/morderranges/location/${locationId}`);
  };
  const checkRoomRate = function(location) {
    return $http.get(
        'https://os.exp.is/api/roomprices/location_name/' + location);
  };

  const getDurationDetail = function(body) {
    console.log(body);
    return $http.post('https://os.exp.is/api/roomprices/location_spacetype_duration_range', body);
  };

  const getUserDetails = function(body) {
    console.log(body);
    return $http.post('https://uehara.serverless.exp.is/eber/checkhmac', body);
  };

  return {
    // checkAvailability: checkAvailability,
    checkAvailabeRoomType: checkAvailabeRoomType,
    checkRoomRate: checkRoomRate,
    getCloudbedsRoomType: getCloudbedsRoomType,
    setCloudbedsRoomType: setCloudbedsRoomType,
    getUserDetails: getUserDetails,
    checkPmsRoomType: checkPmsRoomType,
    setNightOptions: setNightOptions,
    getNightOptions: getNightOptions,
    getGallery: getGallery,
    getDurationDetail: getDurationDetail,
  };
}

RoomAvailabilitySrvc.$inject = ['$http', 'config'];

module.exports = RoomAvailabilitySrvc;
