require('./lib/global');
require('./assets/icons/slp/fonts/slp.woff');
require('./assets/icons/slp/styles.css');
require('./index.css');

require('angularjs-slider/dist/rzslider.css');
require('angularjs-slider/dist/rzslider.js');
require('angular-translate');
// --------------------------------------
// Dependencies
// --------------------------------------
require('auth0-lock');
require('auth0-angular');
require('angular-jwt');
require('angular-storage');
require('angular-sanitize');
require('angular-stripe-checkout');
// require('angular-touch');
require('angularfire');
// [Github] flowjs/flow.js
require('@flowjs/flow.js');
require('./shared/ng-flow.js');
// [Github] urish/angular-moment
require('angular-moment');
// require('angular-aria');
// require('angular-animate');
// require('angular-messages');
require('angular-material');
// [Github] HackedByChinese/ng-idle
require('ng-idle');
// [Github] cgross/angular-busy
require('angular-busy/dist/angular-busy');
require('angular-busy/dist/angular-busy.min.css');
// For dropdown timepicker
require('./assets/angular-semantic-ui.min.js');
// [Github] dwmkerr/angular-modal-service,
require('angular-modal-service');
// require('swiper');
// require('swiper/swiper-bundle.min.css');
/* eslint-disable no-unused-vars, no-var */
// Sentry.io
var Raven = require('raven-js');
Raven
    .config('//6db57d7d3ba5461faa896f064d5a5085@sentry.io/117721')
    .addPlugin(require('raven-js/plugins/angular'), angular)
    .install();

// firebase
global.firebase = require('firebase');
var fbConfig = {
  apiKey: 'AIzaSyBXy6uGFVY5bKYWIAf7ySIYYtj68rWnd8g',
  authDomain: 'sleeep-ffdf9.firebaseapp.com',
  databaseURL: 'https://sleeep-ffdf9.firebaseio.com',
  projectId: 'sleeep-ffdf9',
  storageBucket: 'sleeep-ffdf9.appspot.com',
  messagingSenderId: '607773341059',
};
global.firebase.initializeApp(fbConfig);

// epoberezkin/ajv, a JSON Schema Validator
// var ajv = require('./lib/ajv');

// --------------------------------------
// Init app
// --------------------------------------

var app = angular.module('app', [
  'ngRoute',
  'ngAnimate',
  'ngSanitize',
  'angularify.semantic.dropdown',
  'ngRaven',
  'ngIdle',
  // 'ngTouch',
  'ngMaterial',
  'ngMessages',
  'auth0',
  'angular-jwt',
  'ngCookies',
  'angular-storage',
  'stripe.checkout',
  'flow',
  'angularMoment',
  'angularModalService',
  'cgBusy',
  'rzModule',
  'firebase',
  'pascalprecht.translate',
]);

app.constant('moment', require('moment-timezone'))
    .constant('angularMomentConfig', {
      timezone: APP_CONFIG.LOCATION_INFO.TIMEZONE,
    })
    .config(['$mdGestureProvider', function($mdGestureProvider) {
      $mdGestureProvider.skipClickHijack();
    }])
    .config(['$locationProvider', function($locationProvider) {
      $locationProvider.html5Mode(true);
    }])
    .config(require('./providers/idleProvider.config.js'))
    .config(require('./providers/routeProvider.config.js'))
    .config(require('./providers/mdDateLocaleProvider.config.js'))
    .config(require('./providers/translateProvider.config.js'))
    // .config(require('./providers/authProvider.config.js'))
    .value('config', APP_CONFIG)

// --------------------------------------
// Start Views Components
// --------------------------------------
    .controller('NavCtrl', ['$scope', function($scope) {
      $scope.$on('login.success', function(event, profile) {
        $scope.avatarUrl = profile.picture;
      });
    }])
    .controller('Ctrl', function($scope, $translate) {
      $scope.changeLanguage = function(key) {
        $translate.use(key);
      };
    })
    .controller('BookingCtrl', require('./views/booking.ctrl.js'))
    .controller('CheckInStepTokenCheckCtrl', require('./components/views/checkin_step/token_check.js'))
    .component('bookStepDatePick', require('./components/views/book_step/datepick.js'))
    .component('bookServiceDatePick', require('./components/views/book_service/datepick.js'))
    .component('bookStepSelectRoom', require('./components/views/book_step/select-room.js'))
    .component('bookStepDatePickConfirm', require('./components/views/book_step/datepick-confirm.js'))
    .component('bookStepDetails', require('./components/views/book_step/details.js'))
    .component('bookStepConfirm', require('./components/views/book_step/confirm.js'))
    .component('bookStepCompleted', require('./components/views/book_step/completed.js'))
    .component('checkinStepVerification', require('./components/views/checkin_step/verification.js'))
    .component('checkinStepArrivalSelect', require('./components/views/checkin_step/arrival_select.js'))
    .component('checkinStepCheckedin', require('./components/views/checkin_step/checkedin.js'))
    .component('checkinStepPersonalize', require('./components/views/checkin_step/personalize.js'))
    .component('checkinStepReview', require('./components/views/checkin_step/review.js'))
    .component('checkinKioskStart', require('./components/views/checkin_kiosk/start.js'))
    .component('checkinKioskContacts', require('./components/views/checkin_kiosk/contacts.js'))
    .component('checkinKioskImage', require('./components/views/checkin_kiosk/image.js'))
    .component('checkinKioskAgree', require('./components/views/checkin_kiosk/agree.js'))
    .component('checkinKioskDone', require('./components/views/checkin_kiosk/done.js'))
    .component('error', require('./components/views/error/error'))
    .component('paymeCtrl', require('./components/views/payme/confirm'))
    .component('inPanelControls', require('./components/views/inpanel/controls.js'))
    .component('inPanelMenu', require('./components/views/inpanel/menu.js'))
    .component('inPanelChat', require('./components/views/inpanel/chat.js'))
    .component('stripePos', require('./components/views/stripe-pos.js'))
    .component('passportUpload', require('./components/views/checkin_step/passport_upload.js'))
// .component('sms', require('./components/views/sms.js'))
// --------------------------------------
// End of views
// --------------------------------------

// --------------------------------------
// Start Generic Components
// --------------------------------------
    .directive('loginButton', function() {
      return require('./components/login-button.js');
    })
    .config(['$provide', function($provide) {
      const DEFAULT_TIMEZONE = APP_CONFIG.LOCATION_INFO.GMT;

      $provide.decorator('dateFilter', ['$delegate', function($delegate) {
        const oldDelegate = $delegate;

        const standardDateFilterInterceptor = function(date, format, timezone) {
          if (angular.isUndefined(timezone)) {
            timezone = DEFAULT_TIMEZONE;
          }
          return oldDelegate.apply(this, [date, format, timezone]);
        };

        return standardDateFilterInterceptor;
      }]);
    }])
    .directive('datepickerLocaldate', ['$parse', function($parse) {
      // TODO: got this from here: https://gist.github.com/weberste/354a3f0a9ea58e0ea0de
      // but it is not yet working as expected. – Jun 2020.2.12
      var directive = {
        restrict: 'A',
        require: ['ngModel'],
        link: link,
      };
      return directive;

      function link(scope, element, attr, ctrls) {
        var ngModelController = ctrls[0];

        // called with a JavaScript Date object when picked from the datepicker
        ngModelController.$parsers.push(function(viewValue) {
          // undo the timezone adjustment we did during the formatting
          viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
          // we just want a local date in ISO format
          return viewValue.toISOString().substring(0, 10);
        });

        // called with a 'yyyy-mm-dd' string to format
        ngModelController.$formatters.push(function(modelValue) {
          if (!modelValue) {
            return undefined;
          }
          // date constructor will apply timezone deviations from UTC
          // (i.e. if locale is behind UTC 'dt' will be one day behind)
          var dt = new Date(modelValue);
          // 'undo' the timezone offset again (so we end up on the original date again)
          dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
          return dt;
        });
      }
    }])
    .component('flowProgressIndicator', require('./components/flow-progress-indicator.js'))
    .component('progressIndicator', require('./components/progress_indicator.js'))
    .component('progressIndicatorService', require('./components/progress_indicator_service.js'))
    .component('datePicker', require('./components/datepicker.js'))
    .component('timePicker', require('./components/timepicker.js'))
    .component('imageUploader', require('./components/image-uploader.js'))
    .service('CheckInSrvc', require('./services/checkin.js'))
    .service('FlowSrvc', require('./services/flow.js'))
    .service('StripeSrvc', require('./services/stripe.js'))
    .service('UserSrvc', require('./services/user.js'))
    .service('PaymentSrvc', require('./services/payment.js'))
    .factory('ReserveDetailsSrvc', require('./services/reserve-details.js'))
    .service('RoomAvailabilitySrvc', require('./services/room_availability.js'))
    .service('PayMeSrvc', require('./services/payme.js'))
    .service('APISrvc', require('./services/api.js'))
    .service('DiscountSrvc', require('./services/discount.js'))
    .service('CouponSrvc', require('./services/coupon.js'))
    .run([
      '$rootScope',
      'auth',
      'store',
      'jwtHelper',
      '$templateCache',
      '$window',
      'Idle',
      'FlowSrvc',
      'UserSrvc',
      '$location',
      '$translate',
      function($rootScope, auth, store, jwtHelper, $templateCache, $window, Idle, FlowSrvc, UserSrvc, $location, $translate) {
        auth.hookEvents();
        window.scrollTo(0, 0);
        $rootScope.isKioskMode = false;
        if ($location.search().kiosk === '1') {
          $rootScope.isKioskMode = true;
          console.log('Kiosk Mode');
        }
        $rootScope.languageDisplayList = {'en': 'en', 'zh-HK': '中', 'zh-tw': '中', 'ja': '日'};
        $rootScope.languageList = APP_CONFIG.TRANSLATION.LANGUAGE_LIST;
        $rootScope.showSwitchLangButton = APP_CONFIG.TRANSLATION.SHOW_SWITCH_BUTTON;
        $rootScope.back = function() {
          $window.history.back();
        };
        $rootScope.dimmed = false;
        $rootScope.change = function() {
          $window.dataLayer.push({event: 'languageChange', lang: $rootScope.language});
          $translate.use($rootScope.language);
        };
        // Passing Config variables to ejs
        $rootScope.language = APP_CONFIG.TRANSLATION.DEFAULT_LANGUAGE;
        $rootScope.logoUrl = APP_CONFIG.SITE_LOGO;
        $rootScope.brandName = APP_CONFIG.BRAND_NAME;
        $rootScope.startUrl = APP_CONFIG.SITE_URL;
        $rootScope.creditCardLogo = APP_CONFIG.LOCATION_INFO.CREDIT_CARD_LOGO;
        $rootScope.supportPayMe = _.includes(APP_CONFIG.OPERATIONS.PAYMENT_METHOD_LIST, 'PayMe');
        var everywhere = angular.element(window.document);
        $rootScope.inUse = false;
        everywhere.bind('click', function(event) {
          $rootScope.inUse = true;
          console.log('App is now in use.');
          everywhere.unbind('click');
        });

        // start watching when the app runs. also starts the Keepalive service by default.
//         Idle.watch();

//         $rootScope.$on('IdleTimeout', function() {
//           console.log('timeout');
//           FlowSrvc.toErrorPageIfInProgressButIdle();
//           // the user has timed out (meaning idleDuration + timeout has passed without any activity)
//           // this is where you'd log them
//         });

        // $scope.vm.showLoginModal = function() {
        //   ModalService.showModal({
        //     template: require('./login_modal.html'),
        //     bodyClass: 'modal-backdrop',
        //     controller: ['$scope', 'close', function($scope, close) {
        //       $scope.close = close;
        //     }],
        //   }).then(function(modal) {
        //     modal.element.addClass('open');
        //   });
        // };

        $templateCache.put('semantic-loader.html', '<div class="ui active centered inline loader"></div>');

        $rootScope.$on('$locationChangeStart', function() {
          const token = store.get('token');
          if (token) {
            /* eslint-disable no-negated-condition */
            if (!jwtHelper.isTokenExpired(token)) {
              if (!auth.isAuthenticated) {
                auth.authenticate(store.get('profile'), token).then(function(profile) {
                  console.log('Logged in via refresh token and got profile');
                  console.log(profile);
                  // same action as _updateProfile(profile, idToken) @ login-button.js
                  UserSrvc.set({profile, idToken: token});
                  store.set('profile', profile);
                  store.set('token', token);
                  $rootScope.$broadcast('login.success', profile);
                  // Successful login, now redirect to secured content.
                }, (err) => {
                  console.log(err);
                });
              }
            } else {
              // Either show Login page or use the refresh token to get a new idToken
            }
          }
        });
        // Hack as title out of angular
        $rootScope.$on('$routeChangeSuccess', function(event, currentRoute, previousRoute) {
          if (_.startsWith(_.get(currentRoute, '$$route.originalPath'), '/booking/')) {
            document.title = APP_CONFIG.SITE_NAME + ' | Booking';
          } else if (_.startsWith(_.get(currentRoute, '$$route.originalPath'), '/checkin')) {
            document.title = APP_CONFIG.SITE_NAME + ' | Check-in';
          }
          $window.scrollTo(0, 0);
          console.log('trigger gtm');
          $window.dataLayer.push({event: 'pageview'});
          // console.log("-----------------------------------------------");
          // console.log("Route $routeChangeSuccess", event, currentRoute, previousRoute);
          // console.log("-----------------------------------------------");
          _.set($rootScope, 'previousRoute', previousRoute);
        });
      },
    ]);
/* eslint-enable no-unused-vars, no-var */
