const template = require('./datepick.html');
require('./datepick.css');


const DatePickCtrl = (
    $scope,
    $location,
    ReserveDetailsSrvc,
    RoomAvailabilitySrvc,
    DiscountSrvc,
    CouponSrvc,
    APISrvc,
    FlowSrvc,
    PaymentSrvc,
    UserSrvc
) => {
  console.log('????????????');
  const urlParams = _.pick($location.search(), ['hour', 'coupon']);
  $scope.vm.duration = null;
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  // dirty hack for accept bookingType while clean it up. rely on reloadOnSearch = false
  // console.log('----- from param', urlParams);
  if (urlParams.hour) {
    $scope.vm.bookingType = 'hour';
    $location.search('hour', null);
  }
  if (urlParams.coupon) {
    // console.log(urlParams.coupon);
    CouponSrvc.setCouponCode(urlParams.coupon);
    console.log(CouponSrvc.getCouponCode());
    console.log('``````````````');
  }
  function _initTimeRange(start, end) {
    console.log('_initTimeRange');
    console.log(start);
    console.log(end);
    start = start || null;
    end = end || null;
    // checkin range 9:00am - 11:00pm, 15min step
    // no default
    return {
      // TODO nearest next 30 step
      start,
      end,
      get diff() {
        return moment(end).diff(moment(start), 'hours');
      },
      get hoursToDayEnd() {
        // 2359
        return moment().endOf('day').diff(moment(start), 'hours') + 1;
      },
      _toCalendar: function(date) {
        return moment(date).calendar(null, {sameDay: '[Today]', nextDay: '[Tomorrow]', sameElse: 'DD/MM/YYYY'});
      },
      get startToCalendar() {
        return this._toCalendar(start);
      },
      get endToCalendar() {
        return this._toCalendar(end);
      },
    };
  }
  $scope.vm.timeRange = _initTimeRange();
  console.log($scope.vm.timeRange);
};

DatePickCtrl.$inject = [
  '$scope',
  '$location',
  'ReserveDetailsSrvc',
  'RoomAvailabilitySrvc',
  'DiscountSrvc',
  'CouponSrvc',
  'APISrvc',
  'FlowSrvc',
  'PaymentSrvc',
  'UserSrvc',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    onsubmit: '&',
    new: '@',
  },
  controller: DatePickCtrl,
};
