const mapper = module.exports = {};
// similar to asDBUser @ mapper/details_auth0.js
mapper.asReserveDetailGuest = function(guestDetail) {
  const _fromAuth0 = guestDetail.profile || {};
  const _fromExp = guestDetail.details || {};

  let gender = _fromAuth0.gender;

  switch (_.lowerCase(_fromExp.gender)) {
    case 'm':
      gender = 'male';
      break;
    case 'f':
      gender = 'female';
      break;
    default:
      if (!gender) {
        gender = 'other';
      }
  }

  const isVerifiedUser = _.get(_fromExp, 'details_verified') && _.get(_fromExp, 'email_verified');

  const mappedDetails = {
    firstName: (isVerifiedUser) ? _fromExp.first_name : _fromAuth0.family_name || _fromExp.first_name,
    lastName: (isVerifiedUser) ? _fromExp.last_name : _fromAuth0.given_name || _fromExp.last_name,
    gender,
    email: (isVerifiedUser) ? _fromExp.email : _fromAuth0.email || _fromExp.email,
    dob: (isVerifiedUser) ? _fromExp.dob : _fromAuth0.birthdate || _fromExp.dob,
    country: (isVerifiedUser) ? _fromExp.country : _fromAuth0.country || _fromExp.country,
    phone: (isVerifiedUser) ? _fromExp.phone : _fromAuth0.phone_number || _fromExp.phone,
  };

  // remove all falsey values
  // mappedDetails = _.pickBy(mappedDetails, _.identity);

  return _.pickBy(mappedDetails, _.identity);

  // TO DO: fixture
  // validate format
  // firstName: 'Tim',
  // lastName: 'Tester'
  // gender: 'male',
  // email: 'timching95@gmail.com',
  // dob: moment('1999-01-01'),
  // country: 'HK', // a alpha2Code
  // phone: '12345678'
};
