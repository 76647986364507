const Discount = require('../lib/types/discount');
const Coupon = require('../lib/types/coupon');

function DiscountSrvc() {
  const _discounts = [];

  // --------------------------------------
  // | Global Discounts Rules && Logics
  // --------------------------------------

  // earlyBird, auto apply coupon
  const _earlyBird = function() {
    // TO DO: this coupon should loaded from db through api
    const earlybird_coupon = {
      startDate: '2018-05-25',
      expiryDate: '2018-10-31',
      couponCode: 'Earlybird8237',
      email: '',
      roomType: '45m,1h,2h,4h,8h,12h',
      notes: '',
      discountType: 'relative',
      discountValue: 0.3,
      disabled: false,
    };

    const _discount = Discount.factory({
      name: 'earlyBird',
      couponAsDiscount: true,
      coupon: Coupon.factory(earlybird_coupon),
    });

    const checkinHr = 800;
    const checkoutHr = 1200;
    const spec = {
      checkinHr,
      checkoutHr,
      enabled: function(startDateTime, duration) {
        return _checkBookingRange(startDateTime, duration, checkinHr, checkoutHr);
      },
      global: true,
    };
    _.merge(_discount, spec);
    return _discount;
  };

  // sleepyHour, auto apply coupon
  const _sleepyHour = function() {
    // TO DO: this coupon should loaded from db through api
    const sleepyhour = {
      startDate: '2018-05-25',
      expiryDate: '2018-10-31',
      couponCode: 'SleepyHour2348',
      email: '',
      roomType: '45m,1h,2h,4h,8h,12h',
      notes: '',
      discountType: 'relative',
      discountValue: 0.3,
      disabled: false,
    };

    const _discount = Discount.factory({
      name: 'sleepyHour',
      couponAsDiscount: true,
      coupon: Coupon.factory(sleepyhour),
    });

    const checkinHr = 1700;
    const checkoutHr = 2100;
    const spec = {
      checkinHr,
      checkoutHr,
      enabled: function(startDateTime, duration) {
        return _checkBookingRange(startDateTime, duration, checkinHr, checkoutHr);
      },
      global: true,
    };
    _.merge(_discount, spec);
    return _discount;
  };

  function _checkBookingRange(startDateTime, duration, checkinHr, checkoutHr) {
    // only apply for hourly plan
    if (duration > 8 || !moment(startDateTime).isValid) {
      return false;
    }
    startDateTime = moment(startDateTime);
    const startTime = _.cloneDeep(startDateTime).format('HHmm');
    const checkoutTime = _.cloneDeep(startDateTime).add(duration, 'hours').format('HHmm');
    return startTime >= checkinHr && checkoutTime <= checkoutHr && checkoutTime > startTime;
  }

  // --------------------------------------
  // | Set Global Discounts
  // --------------------------------------

  // TO DO: different types of discount, and check isEnabled by type before apply @ controllers
  _addGlobalDiscount(_earlyBird());
  _addGlobalDiscount(_sleepyHour());

  function _addGlobalDiscount(discount) {
    _discounts.push(discount);
  }

  function _getAvailableGlobalDiscounts() {
    // TO DO: filter all disabled discounts
    return _(_discounts).filter(function(discount) {
      return discount.global;
    }).value();
  }

  function _getGlobalDiscountsList() {
    return _discounts;
  }

  function _getDiscountTotal(discount, originalPrice) {
    return (discount.discountType === 'relative') ? originalPrice * discount.discountValue : discount.discountValue;
  }

  return {
    get: _getAvailableGlobalDiscounts,
    listGlobal: _getGlobalDiscountsList,
    getDiscountTotal: _getDiscountTotal,
  };
}
module.exports = DiscountSrvc;
