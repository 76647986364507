/* global _*/
function CouponSrvc() {
  /*
   * Coupon Example, using excel currently, no db currently
   * {
   *   start-date: '2017-05-24',
   *   expiry-date: '2017-05-24',
   *   code: 'Ajkshfjkasdhfjkfh',
   *   code-lowercase: 'ajkshfjkasdhfjkfh',
   *   email: 'timching95@gmail.com' <nullable>,
   *   room-type: '45m,1h,2h,' <nullable>,
   *   notes: ''<nullable>,
   *   use-remaining: 100 <Integer>,
   *   times-used: 1 <Integer>,
   *   discount-type: 'relative|absolute',
   *   discount-value: '0.65'<float>,
   *   disabled: true <boolean>
   * }
   */
  let couponCode = '';
  function _getCouponCode() {
    return couponCode;
  }
  function _setCouponCode(coupon) {
    couponCode = coupon;
  }
  function _getDiscountTotal(coupon, originalPrice) {
    if (!coupon.isValid) {
      return 0;
    }

    return (coupon.discountType === 'relative') ? originalPrice * coupon.discountValue : coupon.discountValue;
  }

  return {
    getDiscountTotal: _getDiscountTotal,
    getCouponCode: _getCouponCode,
    setCouponCode: _setCouponCode,
  };
}
module.exports = CouponSrvc;
