// resolvers
const ReserveDetailsResolver = require('./routeResolvers/ReserveDetailsResolve');

const FlowResolver = {
  booking: require('./routeResolvers/BookingFlowResolve'),
};

function routeProviderConfig($routeProvider, StripeCheckoutProvider) {
  StripeCheckoutProvider.defaults({
    key: APP_CONFIG.STRIPE_API_KEY,
  });

  $routeProvider
      .when('/booking', {
        redirectTo: '/booking/date-pick',
      })
      .when('/booking/date-pick', {
        template: '<book-step-date-pick new="true"></book-step-date-pick>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false,
      })
      .when('/booking/date-pick/edit', {
        template: '<book-step-date-pick new="false"></book-step-date-pick>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false,
      })
      .when('/booking/select-room', {
        template: '<book-step-select-room new="true"></book-step-select-room>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false,
      })
      .when('/booking/select-room/edit', {
        template: '<book-step-select-room new="false"></book-step-select-room>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false,
      })
      .when('/booking/details', {
        template: '<book-step-details></book-step-details>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        resolve: {
          bookingResolve: FlowResolver.booking,
          cachedDetails: ReserveDetailsResolver,
        },
      })
      .when('/booking/confirm', {
        template: '<book-step-confirm></book-step-confirm>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        resolve: {
          bookingResolve: FlowResolver.booking,
          cachedDetails: ReserveDetailsResolver,
          stripe: StripeCheckoutProvider.load,
        },
      })
      .when('/booking/completed', {
        template: '<book-step-completed></book-step-completed>',
        controller: 'BookingCtrl',
        controllerAs: 'vm',
        resolve: {
          cachedDetails: ReserveDetailsResolver,
        },
      })
      .when('/error', {
        template: '<error></error>',
        controllerAs: 'vm',
      })
      .when('/error/:message', {
        template: '<error></error>',
        controllerAs: 'vm',
      })
      .when('/payme', {
        template: '<paymeCtrl></paymeCtrl>',
        controllerAs: 'vm',
      })
      .otherwise('/booking');
}

routeProviderConfig.$inject = ['$routeProvider', 'StripeCheckoutProvider'];
module.exports = routeProviderConfig;
