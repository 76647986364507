/**
* Refer to types/reserve-detail.js for overall type design
*/

const factory = function(coupon) {
  const SHEET_FORMAT = 'YYYY-MM-DD';

  const _coupon = _.cloneDeep(coupon);
  if (_.isEmpty(_coupon)) {
    return;
  }

  if (_coupon.roomType) {
    _coupon.roomType = _(_coupon.roomType).split(',').filter().value();
  }

  if (_coupon.startDate) {
    _coupon.startDate = moment(_coupon.startDate);
  }

  if (_coupon.expiryDate) {
    _coupon.expiryDate = moment(_coupon.expiryDate);
  }

  Object.defineProperty(_coupon, 'codeLowercase', {
    get: function() {
      return _.toLower(this.couponCode);
    },
    enumerable: true,
  });

  // TO DO: combine `isStartDateBfrCouponExpiry` & `isStartDateAftrCouponStart` inDateRange
  Object.defineProperty(_coupon, 'isStartDateBfrCouponExpiry', {
    get: function() {
      // return bookingStartDate < moment(this.expiryDate, SHEET_FORMAT);
      return moment() < moment(this.expiryDate, SHEET_FORMAT);
    },
    enumerable: true,
  });

  Object.defineProperty(_coupon, 'isStartDateAftrCouponStart', {
    get: function() {
      // return bookingStartDate > moment(this.startDate, SHEET_FORMAT);
      return moment() > moment(this.startDate, SHEET_FORMAT);
    },
    enumerable: true,
  });

  // TO DO: create a function to check this email can use the coupon or not
  Object.defineProperty(_coupon, 'isValidEmail', {
    get: function() {
      return (!this.email || _.toLower('timching95@gmail.com') === this.email);
    },
    enumerable: true,
  });

  Object.defineProperty(_coupon, 'isValidRoomType', {
    get: function() {
      // return (_.isEmpty(coupon.roomType) || _.includes(coupon.roomType, _.toUpper(roomType)));
      return (_.isEmpty(this.roomType) || _.includes(this.roomType, _.toLower('12H')));
    },
    enumerable: true,
  });

  Object.defineProperty(_coupon, 'enabled', {
    get: function() {
      return !this.isDisabled;
    },
    enumerable: true,
  });

  Object.defineProperty(_coupon, 'isValid', {
    get: function() {
      return this.isStartDateBfrCouponExpiry &&
        this.isStartDateAftrCouponStart &&
        this.isValidEmail &&
        this.isValidRoomType &&
        this.enabled;
    },
    enumerable: true,
  });

  return _coupon;
};

/*
* Coupon Example, using excel currently, no db currently
* {
*   startDate: '2017-05-24',
*   expiryDate: '2017-05-24',
*   code: 'Ajkshfjkasdhfjkfh',
*   codeLowercase: 'ajkshfjkasdhfjkfh',
*   email: 'timching95@gmail.com',
*   roomType: '45m,1h,2h,',
*   notes: '',
*   useRemaining: 100,
*   timesUsed: 1,
*   discountType: 'relative|absolute',
*   discountValue: '0.65',
*   disabled: true
* }
*/

// TO DO: codeLowercase should be a result of getter function
const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    startDate: {
      type: 'object',
      instanceof: 'moment',
    },
    expiryDate: {
      type: 'object',
      instanceof: 'moment',
    },
    couponCode: {
      type: 'string',
    },
    codeLowercase: {
      type: 'string',
    },
    email: {
      type: 'string',
      format: 'email',
    },
    roomType: {
      type: 'array',
    },
    notes: {
      type: 'string',
    },
    useRemaining: {
      type: 'nunmber',
    },
    timesUsed: {
      type: 'nunmber',
    },
    discountType: {
      type: 'string',
    },
    discountValue: {
      type: 'number',
    },
    disabled: {
      type: 'boolean',
    },
  },
  // required: ['code', 'codeLowercase', 'useRemaining', 'discountType', 'discountValue', 'disabled']
  required: ['code', 'codeLowercase', 'discountType', 'discountValue', 'disabled'],
};

module.exports = {
  schema: schema,
  factory: factory,
};
