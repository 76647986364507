/** VO for application use. Not corr to UI now as 1-1 mapping**/
/** Could be OO but keep it simple now **/
/** hard part: initial state vs finalized state e.g. pms_reservation_id **/
const Guest = require('./guest');
const Reservation = require('./reservation');

// Stick with camelCase, although dashedCase is good for db
// becoz we got more complicated fields in UI e.g. amountPerNight
// https://github.com/sequelize/sequelize/issues/2209 auto to db not yet available

// As still simple, Direct `require` instaead of proper reuse by definitions to save the trouble
// https://spacetelescope.github.io/understanding-json-schema/structuring.html
const schema = {
  type: 'object',
  properties: {
    guest: Guest.schema,
    guests: {
      type: 'array',
      items: Guest.schema,
    },
    reservation: Reservation.schema,
  },
  required: ['guests', 'reservation'],
};

// https://nemisj.com/why-getterssetters-is-a-bad-idea-in-javascript/
function ReserveDetails(reservation, guests) {
  const _reservation = Reservation.factory(reservation);
  let _guests = _.cloneDeep(guests);
  if (!_.isArray(_guests)) {
    _guests = [_guests];
  }
  _guests = _guests.map(Guest.factory);
  return _.assign(this, {
    reservation: _reservation,
    guests: _guests,
  });
}
// magic utilto break multiple @ReserveDetails with multiple guests into @ReserveDetails with single guest
// as we do that for Cloudbeds & DB
ReserveDetails.prototype.asReserveDetailsByGuestEmail = function() {
  const self = this;
  return _(this.guests)
      .map(function(guest) {
        return new ReserveDetails(self.reservation, guest);
      })
      .keyBy(function(details) {
        return details.guest.email;
      })
      .value();
};
// ReserveDetails.prototype.toString = function() {
//   console.log('use new toString');
//   var copy = _.cloneDeep(this);
//   copy.reservation.startDate = this.reservation.startDate.format();
//   copy.reservation.endDate = this.reservation.endDate.format();
//   return copy.toString();
// };
Object.defineProperty(ReserveDetails.prototype, 'guest', {
  get: function() {
    return _.head(this.guests);
  },
  enumerable: true,
});

module.exports = {
  schema: schema,
  // rename as type now it is constructor
  type: ReserveDetails,
};
