const template = require('./controls.html');

require('./controls.css');
const ENDPOINT = 'http://server.sleeep.io';
// TO DO token logic https://trello.com/c/Aml8EL57/75-peter-s-api-to-vincent
const token = 123;

const ControlsCtrl = function($scope, $http) {
  $scope.vm.lightSlider = {
    value: 10,
    options: {
      floor: 0,
      ceil: 10,
      hidePointerLabels: true,
      hideLimitLabels: true,
      getTickColor: function(value) {
        if (value < 3) {
          return 'red';
        }
        if (value < 6) {
          return 'orange';
        }
        if (value < 9) {
          return 'yellow';
        }
        return '#2AE02A';
      },
    },
  };

  $scope.$on('slideEnded', _.debounce(function() {
    console.log('req to update light');
    $http.post([ENDPOINT, ''].join('/'), {
      token: token,
      bri: $scope.vm.lightSlider.value,
      target: 'bed',
    });
  }, 500, {maxWait: 1000}));
};
ControlsCtrl.$inject = ['$scope', '$http'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ControlsCtrl,
};
