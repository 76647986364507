// var Promise = require('bluebird');

const StripeSrvc = function(StripeCheckout) {
  const _handler = StripeCheckout.configure({
    name: APP_CONFIG.SITE_NAME,
    // global token callback
    token: function() {},
  });

  return {
    /**
     * @param  {[type]} options Stripe option e.g. description, amount
     * @return {[type]}         [description]
     */
    doCheckout: function(options) {
      return _handler.open(options)
          .then(function(result) {
            return result;
          });
    },
  };
};

StripeSrvc.$inject = ['StripeCheckout'];
module.exports = StripeSrvc;
