const template = require('./agree.html');

const AgreeCtrl = function($scope, $location, FlowSrvc, ReserveDetailsSrvc) {
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  // force enter & override
  $scope.vm.kioskSteps = APP_CONFIG.CHECKIN_KIOSK_STEPS_LABELS;
  $scope.vm.kioskStepsUrl = APP_CONFIG.CHECKIN_KIOSK_STEPS_URLS;

  console.log('agreement');
  console.log($scope.vm.reserveDetails);
  // Magic to open door
  //
  $scope.vm.submit = function() {
    FlowSrvc.toFlow('CHECKIN_KIOSK');
    FlowSrvc.next();

    // TO DO loading effect
  };
};

AgreeCtrl.$inject = ['$scope', '$location', 'FlowSrvc', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: AgreeCtrl,
};
