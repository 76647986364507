const mapper = module.exports = {};

// clone mapper from guestMapper
// But user and guest will not work for later while migration done
// becoz user will have their profile, but guest not

const guestMapper = require('./guest');

mapper.asReserveDetailGuest = function(guestDetail) {
  // const _fromAuth0 = guestDetail.profile || {};
  const _fromExp = guestDetail.details || {};

  const mappedDetails = guestMapper.asReserveDetailGuest(guestDetail);

  if (_fromExp.details_verified) {
    _.merge(mappedDetails, {details_verified: _fromExp.details_verified});
  }

  return mappedDetails;
};
