const template = require('./review.html');
require('./review.css');
const i18n = require('../../../lib/i18n');
const ReviewCtrl = function($scope, FlowSrvc, APISrvc, ReserveDetailsSrvc) {
  $scope.vm.checkinSteps = APP_CONFIG.CHECKIN_STEPS_LABELS;
  $scope.vm.checkinStepsUrl = APP_CONFIG.CHECKIN_STEPS_URLS;

  // CheckInSrvc.redirectIfOutOfSession();
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gtm = APP_CONFIG.LOCATION_INFO.GTM;
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  console.log($scope.vm.reserveDetails);
  $scope.vm.startDate = moment($scope.vm.reserveDetails.reservation.startDate).toDate();
  $scope.vm.endDate = moment($scope.vm.reserveDetails.reservation.endDate).toDate();
  $scope.vm.getPersonalizationlabel = i18n.getPersonalizationlabel;
  $scope.vm.submit = function() {
  // confirm check in
  // TO DO see if need to refactor for guestSrvc at checkin
    APISrvc.preCheckIn($scope.vm.reserveDetails)
        .then(function() {
          FlowSrvc.next();
        })
        .catch(function() {
          FlowSrvc.error();
        });
  };
};

ReviewCtrl.$inject = ['$scope', 'FlowSrvc', 'APISrvc', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ReviewCtrl,
};
