const API = function($http, config) {
  const _api = {};
  function postAPI(endpoint, body) {
    return $http.post(config.API_PATH + endpoint, body);
  }
  // TO DO structure for price & total
  _api.reserve = function(details) {
    console.log(details);
    return $http.post(
        'https://uehara.serverless.exp.is/pms/spaces',
        details);
  };

  _api.getOrderInfo = function(oid) {
    console.log(oid);
    return $http.get(
        `https://uehara.serverless.exp.is/pms/spaces/${oid}`);
  };

  _api.getUserDetails = function(body) {
    console.log(body);
    return $http.post('https://uc2dgaks9j.execute-api.ap-northeast-1.amazonaws.com/default/exp_check_hmac_and_get_user_detail', body);
  };

  _api.validateCheckInSession = function(tokenString) {
    return postAPI('/checkin-session', {tokenString: tokenString});
  };

  _api.validateCheckInSessionOta = function(cloudbedsId) {
    return postAPI('/checkin-session-ota', {cloudbedsId: cloudbedsId});
  };

  // @deprecated endpoint to trigger re-send confirm email
  // var sendConfirm = function(reservationDetails, guestsByReservationId) {
  //   return postAPI('/send-confirm', {
  //     'reservation': reservationDetails,
  //     'guestsByReservationId': guestsByReservationId
  //   });
  // };

  _api.sendQRCode = function(reservationId, email) {
    return postAPI('/send-qrcode', {
      reservationId: reservationId,
      email: email,
    });
  };

  _api.getCouponDetails = function(couponCode) {
    return $http.post('https://uehara.serverless.exp.is/pms/checkcoupon', {
      couponCode,
      showDetails: true,
    });
  };

  _api.validateCouponCode = function(couponCode, startDate, endDate, email, roomType, location) {
    return $http.post('https://uehara.serverless.exp.is/pms/coupons', {
      couponCode: couponCode,
      startDate: startDate,
      endDate: endDate,
      email: email,
      plan: roomType,
      location: location,
    });
  };

  // @deprecated as SMS feature on hold
  // var requestSMSCode = function(mobileNumber, countryCode) {
  //   return postAPI('/sms', {
  //     'mobileNumber': mobileNumber,
  //     'countryCode': countryCode
  //   });
  // };

  // @deprecated as SMS feature on hold
  // var verifySMSCode = function(mobileNumber, countryCode, code) {
  //   return postAPI('/sms-verify', {
  //     'mobileNumber': mobileNumber,
  //     'countryCode': countryCode,
  //     'code': code
  //   }).then(function(result) {
  //     return result.data.success;
  //   });
  // };
  _api.preCheckIn = function(details) {
    // TO DO mobile number save at this
    return postAPI('/precheckin', details);
  };

  _api.charge = function(amount, note, stripeToken) {
    return postAPI('/charge', {
      amount: amount,
      note: note,
      stripeToken: stripeToken,
    });
  };

  return _api;
};

API.$inject = ['$http', 'config'];

module.exports = API;
