function translateProviderConfig($translateProvider) {
  $translateProvider.translations('en', {
    book_by: 'Book By',
    hour: 'Hour',
    night: 'Night',
    in: 'In:',
    out: 'Out:',
    for_large_party: 'For large party size:<br/>Please <a href="mailto:{{email}}">email</a> or <a href="tel:{{tel}}">call</a> us directly.',
    checking_availability: 'Checking Availability',
    booking_for: '<strong>{{ diff }}</strong> nights x',
    booking_type: 'Booking Type:',
    staying_for: 'Staying for:',
    arriving_on: 'Arriving on:',
    at: 'at:',
    your_arrival_time: 'Your Arrival Time',
    timezone: 'Time-zone:',
    check_in: 'Check-in:',
    check_out: 'Check-out:',
    book_now: 'Book Now',
    room_type: 'Room Type:',
    total_price: 'Total Price:',
    no_rooms_available_now: 'No rooms available now.',
    plesae_pick_another_time: 'Plesae pick another time?',
    guest: 'Guest',
    first_name: 'First Name:',
    please_enter_first_name: 'Please enter First Name ',
    middle_name: 'Middle Name (if any):',
    please_enter_middle_name: 'Please enter Middle Name ',
    last_name: 'Last Name:',
    please_enter_last_name: 'Please enter Last Name ',
    email_address: 'Email Address:',
    please_enter_email: 'Please enter Email',
    email_address_is_invalid: 'Email Address is invalid',
    country: 'Country:',
    please_enter_country: 'Please enter Country',
    pick_a_country: 'Pick a country.',
    gender: 'Gender:',
    please_select_gender: 'Please select Gender',
    male: 'male',
    female: 'female',
    other: 'other',
    phone_code: 'Phone Code:',
    please_enter_phoneCode: 'Please enter phoneCode',
    phoneno: 'Phone No:',
    please_enter_Phone_no: 'Please enter Phone no.',
    date_of_birth: 'Date of birth:',
    please_enter_correct_DOB: 'Please enter correct DOB',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    for: 'for:',
    no_of_night: 'No. of night(s)',
    price: 'Price',
    discount: 'Discount',
    total: 'Total Price',
    payment_method: 'Payment Method:',
    apply: 'APPLY',
    coupon_code_cannot_be_applied: 'Coupon code cannot be applied',
    coupon_applied: 'Coupon applied',
    remove: 'Remove',
    exp: 'XP.',
    to_be_awarded_at_check_out: 'to be awarded at check-out',
    i_agree_to_the_tc: 'I agree to the <a href="{{ tncUrl }}" target="_blank" >terms &amp; conditions</a>.',
    confirm_pay: 'Confirm &amp; Pay',
    confirmed_statement: 'Your booking request has been made.<br/><strong>Once the booking has been confirmed by our X.O.</strong><br/>a confirmation email will be sent to <strong>{{ email }}</strong><br/>Your will not be charged until your booking is confirmed.',
    details: 'Details',
    booking_request: 'Booking Request',
    access: 'Access',
    directions: 'Directions:',
    actual_address: 'Actual Address:',
    open_in_google_map: 'Open in Google Map',
    need_help: 'Need Help?',
    contacts: 'Contacts:',
    contact_statement: 'Feel free to contact our Experience Officers (X.O.) at any time via',
    email: 'Email',
    phone: 'Phone',
    sleeep_well: 'Sleeep well,',
    xo: 'X.O.',
    ps_please_follow_us: 'p.s. Please follow us:',
    oops: 'Oops',
    session_expired: 'Session Expired.',
    please_try_again: 'Please try again.',
    go_back: 'Go Back.',
    contact_us: 'Contact Us',
    payme_step_label_1: 'Open the PayMe app.',
    payme_step_label_2: 'Scan the PayCode to authorise payment.',
    payme_step_label_3: 'Complete payment in the app and wait for confirmation.',
    mobi_payme_desc: 'You have selected PayMe as your payment method and will be redirected to the PayMe app to complete payment.',
    cancel_payment: 'Cancel Payment',
    complete_payment_in_payme: 'I have complete payment in PayMe app.',
    scan_this_paycode: 'Scan this PayCode with PayMe',
    dont_close_this_page: 'Please do not close this page before payment is complete',
    EA002: 'Sorry, PayMe is currently unavailable but we\'re working hard to fix it! Please try again later or use another payment option.',
    paycode_expired: 'PayCode expired, please make a new booking.',
    not_yet_complete: 'Payment has not been completed, please try again after completion',
    payme_failure: 'Your PayMe payment request failrue. Please try again later or use another payment option.',
    your_payme_request_id: 'Your PayMe Payment Request ID: {{ id }}',
    thank: 'You have finish payment in PayMe. Please further progress in Desktop.',
    slp_in_wallet: '{{ slp }} SLP in wallet',
    slps_are_for_members_only: 'SLPs are for members only.',
    slps_insufficient: 'You have insufficient SLP',
  });
  $translateProvider.translations('zh-tw', {
    book_by: '預約種類',
    hour: '小時',
    night: '晚間',
    in: '開始:',
    out: '完結:',
    for_large_party: '關於大型活動預約:<br/>請直接發送 <a href="mailto:{{email}}">電郵</a> 或 <a href="tel:{{tel}}">致電</a> 我們。',
    checking_availability: '查找中',
    booking_for: '預約 <strong>{{ diff }}</strong> 晚 x',
    booking_type: '預約類別:',
    staying_for: '逗留時長:',
    arriving_on: '抵達日期:',
    at: '時間:',
    your_arrival_time: '您的到達時間',
    timezone: '時區:',
    check_in: '訂單開始:',
    check_out: '訂單結束:',
    book_now: '立即預約',
    room_type: '空間類型:',
    total_price: '總額:',
    no_rooms_available_now: '目前未有空房',
    plesae_pick_another_time: '請選擇其它時段',
    guest: '客人',
    first_name: '名字:',
    please_enter_first_name: '請填寫名字 ',
    middle_name: '中間名（如有）:',
    please_enter_middle_name: '請填寫中間名（如有）',
    last_name: '姓氏:',
    please_enter_last_name: '請填寫姓氏 ',
    email_address: '電郵:',
    please_enter_email: '請填寫電郵',
    email_address_is_invalid: '無效的電郵地址',
    country: '國家:',
    please_enter_country: '請填寫國家',
    pick_a_country: '請填寫國家',
    gender: '性別:',
    please_select_gender: '請選擇性別',
    male: '男',
    female: '女',
    other: '其他',
    phone_code: '電話區號:',
    please_enter_phoneCode: '請填寫電話區號',
    phoneno: '電話:',
    please_enter_Phone_no: '請填寫電話號碼',
    date_of_birth: '出生日期:',
    please_enter_correct_DOB: '請填寫正確出生日期',
    year: '年',
    month: '月',
    day: '日',
    for: '給:',
    no_of_night: '預訂晚數',
    price: '價錢',
    discount: '折扣',
    total: '總額',
    payment_method: '付款方法:',
    apply: '使用',
    coupon_code_cannot_be_applied: '折扣代碼無法使用',
    coupon_applied: '折扣成功',
    remove: '移除',
    exp: 'XP.',
    to_be_awarded_at_check_out: '將於體驗完成後獲得',
    i_agree_to_the_tc: '我同意 <a href="{{ tncUrl }}" target="_blank" >使用條款</a>.',
    confirm_pay: '確認訂單',
    confirmed_statement: '我們已為您進行預訂。<br/><strong>一旦我們的X.O.確認了訂單,</strong><br/>就會將確認電子郵件發送至<strong>{{ email }}</strong><br/>在確認您的訂單之前，我們不會向您收取費用。',
    details: '詳情',
    booking_request: '預約請求',
    access: '進入',
    directions: '路線:',
    actual_address: '地址:',
    open_in_google_map: '在Google Map打開',
    need_help: '需要幫助?',
    contacts: '聯絡方式:',
    contact_statement: '想隨時聯絡我們的體驗專員(X.O.)，按',
    email: '電郵',
    phone: '電話',
    sleeep_well: '祝君好眠，',
    xo: 'X.O.',
    ps_please_follow_us: 'p.s. 請追蹤我們:',
    oops: '糟糕',
    session_expired: '頁面已過期',
    please_try_again: '請重試',
    go_back: '返回',
    contact_us: '聯絡我們',
    payme_step_label_1: '打開 PayMe 應用程式',
    payme_step_label_2: '掃描 PayCode 以授權付款',
    payme_step_label_3: '在 PayMe 完成交易後，請留在此頁面等待確認',
    mobi_payme_desc: '您已選擇以PayMe作為付款方式，並且將被重新定向至PayMe應用程式以完成付款。',
    cancel_payment: '取消付款',
    complete_payment_in_payme: '我已于PayMe應用程式完成付款',
    scan_this_paycode: '請用 PayMe 掃描此 PayCode',
    dont_close_this_page: '在交易完成前請不要關閉此頁面',
    EA002: '對不起, PayMe 目前不可用，但我們正在努力修復！請稍後重試或使用其他付款方式。',
    paycode_expired: 'PayCode 已過期，請重新預訂。',
    not_yet_complete: 'PayMe 付款未完成，請于完成後重試',
    payme_failure: '你于 PayMe 付款失敗，請稍後重試或使用其他付款方式。',
    your_payme_request_id: '你的 PayMe 付款請求ID: {{ id }}',
    thank: '你已在PayMe 完成付款，請回到電腦端進行下一步。',
    slp_in_wallet: '{{ slp }} SLP in wallet',
    slps_are_for_members_only: 'SLPs are for members only.',
    slps_insufficient: 'You have insufficient SLPs',
  });
  $translateProvider.translations('ja', {
    book_by: '',
    hour: '時間貸し',
    night: '宿泊',
    in: '希望到着日:',
    out: '希望最終日:',
    for_large_party: '大人数での予約は:<br/>こちらへ直接ご連絡ください <a href="mailto:{{email}}">メール</a> または <a href="tel:{{tel}}">電話</a> してください。',
    checking_availability: '検索中',
    booking_for: '<strong>{{ diff }}</strong>日間 x',
    booking_type: '時間帯:',
    staying_for: '滞在期間:',
    arriving_on: '到着日:',
    at: '到着時間:',
    your_arrival_time: '到着時間',
    timezone: '時間帯:',
    check_in: 'チェックイン:',
    check_out: 'チェックアウト:',
    book_now: '今すぐ予約',
    room_type: '空間タイプ:',
    total_price: '合計価格:',
    no_rooms_available_now: '現在空室がありません',
    plesae_pick_another_time: '他の時間を選んでください',
    guest: 'お客様',
    first_name: '名:',
    please_enter_first_name: '名前を入力してください ',
    middle_name: 'ミドルネーム:',
    please_enter_middle_name: 'ミドルネームを入力してください',
    last_name: '姓:',
    please_enter_last_name: '姓を入力してください ',
    email_address: 'メールアドレス:',
    please_enter_email: 'メールアドレスを入力してください',
    email_address_is_invalid: '無効なメールアドレスです',
    country: '国:',
    please_enter_country: '国を入力してください',
    pick_a_country: '国を選んでください',
    gender: '性別:',
    please_select_gender: '性別を選んでください',
    male: '男',
    female: '女',
    other: 'その他',
    phone_code: '国別コード:',
    please_enter_phoneCode: '国別コードを入力してください',
    phoneno: '電話:',
    please_enter_Phone_no: '電話番号を入力してください',
    date_of_birth: '生年月日:',
    please_enter_correct_DOB: '生年月日を入力してください',
    year: '年',
    month: '月',
    day: '日',
    for: 'お客様:',
    no_of_night: '✖ 日数',
    price: '価格',
    discount: '割引',
    total: '合計価格',
    payment_method: '支払い方法:',
    apply: '適用',
    coupon_code_cannot_be_applied: '使用できないクーポンです',
    coupon_applied: 'クーポンが適用されました',
    remove: '削除',
    exp: 'XP.',
    to_be_awarded_at_check_out: 'チェックアウト時に加算されます',
    i_agree_to_the_tc: '私は<a href="{{ tncUrl }}" target="_blank" >規約および条件</a>に同意します',
    confirm_pay: 'お支払を確定する',
    confirmed_statement: 'お客様の予約手続きが完了しました。<br/><strong>弊社のX.O.によって予約が確定されると、</strong><br/><strong>{{ email }}</strong>に確認メールが届きます。<br/>予約が確定されるまで、料金は発生しません。',
    details: '詳細',
    booking_request: '予約リクエスト',
    access: 'アクセス',
    directions: '行き方:',
    actual_address: '実際の住所:',
    open_in_google_map: 'Google マップで開く',
    need_help: 'ヘルプが必要ですか？',
    contacts: '連絡先:',
    contact_statement: '弊社のeXperience Officer（X.O.）に次の方法でいつでもお気軽にお問い合わせください',
    email: 'メールアドレス',
    phone: '電話',
    sleeep_well: 'おやすみなさい，',
    xo: 'X.O.',
    ps_please_follow_us: 'p.s. フォローをお願いします:',
    oops: '問題が生じたようです。<br/>はじめからやり直してください。',
    session_expired: 'セッションの期限が切れました。',
    please_try_again: '再度お試しください。',
    go_back: '戻る',
    contact_us: 'お問い合わせ',
    payme_step_label_1: 'PayMeの使用方法',
    payme_step_label_2: 'PayCodeをスキャンしてください',
    payme_step_label_3: 'PayMeでの支払いが終了後、この画面にて待機してください 完成交易後，請留在此頁面等待確認',
    mobi_payme_desc: 'PayMeを選択すると、地頭的にアプリに遷。移するので支払いを完了してください',
    cancel_payment: ' 支払い取り消し',
    complete_payment_in_payme: 'PayMeでの支払いを確認しました',
    scan_this_paycode: 'PayMeでこのPayCodeをスキャンしてください',
    dont_close_this_page: '完了するまでこのページを閉じないでください',
    EA002: '申し訳ございませんが、現在PayMeがご利用いただけないようです。別の支払方法を選択してください。',
    paycode_expired: 'PayCodeの期限が切れています。',
    not_yet_complete: 'PayMeの支払いが完了していません。完了後にもう一度お試しください。',
    payme_failure: '申し訳ございませんが、現在PayMeがご利用いただけないようです。別の支払方法を選択してください。',
    your_payme_request_id: 'あなたのPayMeIDは: {{ id }}です。',
    thank: 'PayMeでの支払いが完了しました。予約ページに戻り、次の手順に進んでください。',
    slp_in_wallet: '{{ slp }} SLP in wallet',
    slps_are_for_members_only: 'SLPs are for members only.',
    slps_insufficient: 'You have insufficient SLPs',
  });
  const detfaultLang = APP_CONFIG.TRANSLATION.DEFAULT_LANGUAGE;
  $translateProvider.preferredLanguage(detfaultLang);
}
translateProviderConfig.$inject = ['$translateProvider'];
module.exports = translateProviderConfig;