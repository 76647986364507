require('./arrival_select.css');
const template = require('./arrival_select.html');

const cbModelTime = require('../../../lib/cloudbeds/model/time');
const ArrivalSelectCtrl = function($scope, $location, APISrvc, UserSrvc, FlowSrvc, ReserveDetailsSrvc) {
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  $scope.vm.arrivalFlight = $scope.vm.reserveDetails.reservation.arrivalFlight || '';
  $scope.vm.prefilledEmail = $scope.vm.reserveDetails.reservation.source === 'OTA' ?
  '' : $scope.vm.reserveDetails.guest.email;
  // TO DO when go back, even ota we should have saved
  $scope.vm.email = $scope.vm.reserveDetails.guest.emailEntered || '';
  // use range to use the time-picker
  $scope.vm.timeRange = {
    start: _.get(
        $scope.vm.reserveDetails,
        'reservation.estimatedArrivalTimeInput',
        $scope.vm.reserveDetails.reservation.startDate
    ),
  };
  // TO DO find email
  $scope.submit = function() {
    $scope.vm.reserveDetails.reservation.estimatedArrivalTime = cbModelTime.create($scope.vm.timeRange.start);
    $scope.vm.reserveDetails.reservation.estimatedArrivalTimeInput = $scope.vm.timeRange.start;
    $scope.vm.reserveDetails.reservation.arrivalFlight = $scope.vm.arrivalFlight;
    $scope.vm.reserveDetails.guest.email = $scope.vm.email || $scope.vm.reserveDetails.guest.email;
    $scope.vm.reserveDetails.guest.emailEntered = $scope.vm.email;

    ReserveDetailsSrvc.set($scope.vm.reserveDetails);

    // check is verified
    const isSameEmail = (_.get(UserSrvc.get(), 'profile.email') === _.get($scope.vm.reserveDetails, 'guest.email'));
    const isVerified = _(_.get(UserSrvc.get(), 'details')).pick(['email_verified', 'details_verified']).filter().size();

    if (isSameEmail && isVerified) {
      // set preferences
      $scope.vm.reserveDetails.reservation.preferences = _.get(UserSrvc.get(), 'reservations.preferences', null);
      ReserveDetailsSrvc.set($scope.vm.reserveDetails);

      // Set passport string for verified user
      ReserveDetailsSrvc.update({
        reservation: {
          passportUrl: 'Verified User, no passport link currently',
          isPassportUploaded: true,
        },
      });

      FlowSrvc.toStep(_.size(FlowSrvc.getCurrentFlowSteps()) - 2);
    }

    FlowSrvc.next();
  };

  $scope.isErr = function(form, field) {
    const formField = form[field];
    if (formField) {
      return Object.keys(form[field].$error).length > 0 && form.$submitted;
    }

    // for arrivalTime only
    const _field = _.get($scope.vm, field);
    let wrongArrivalDateTime;

    // Check it like this because timepick create moment with undefined, moment(undefined)
    // which will return current datetime, so we cannot check timeRange.start is exist or not

    // TO DO: update time pick, but be careful because many pages used this component
    if (moment(_field).format('YMdHm') === moment().format('YMdHm')) {
      wrongArrivalDateTime = true;
    }

    return !!wrongArrivalDateTime && form.$submitted;
  };
};

ArrivalSelectCtrl.$inject = ['$scope', '$location', 'APISrvc', 'UserSrvc', 'FlowSrvc', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ArrivalSelectCtrl,
};
