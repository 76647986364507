const template = require('./image.html');

const ImageCtrl = function($scope, $location, FlowSrvc, ReserveDetailsSrvc) {
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  // force enter & override
  $scope.vm.email = '';
  console.log('arrival');
  $scope.vm.isCameraOnly = $scope.$root.isKioskMode;
  $scope.vm.kioskSteps = APP_CONFIG.CHECKIN_KIOSK_STEPS_LABELS;
  $scope.vm.kioskStepsUrl = APP_CONFIG.CHECKIN_KIOSK_STEPS_URLS;
  // $scope.
  // $scope.$on('uploadUpdated', function() {
  //
  // });
  $scope.vm.isDEV = process.env.NODE_ENV === 'DEV';
  $scope.vm._backdoor = function() {
    FlowSrvc.next();
  };
  $scope.vm.submit = function() {
    // $scope.$broadcast('image-upload');
    // ReserveDetailsSrvc.update({
    //   guest: {
    //     email: $scope.vm.email,
    //     emailAtBooking: $scope.vm.reserveDetails.guest.email
    //   }
    // });

    FlowSrvc.next();
  };
};

ImageCtrl.$inject = ['$scope', '$location', 'FlowSrvc', 'ReserveDetailsSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: ImageCtrl,
};
