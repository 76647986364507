const BookingCtrl = function($scope, $route, $translate) {
  $scope.status = $route.current.$$route.status || 'datepick';
  $scope.setLang = function(langKey) {
    // You can change the language during runtime
    $translate.use(langKey);
  };
  $scope.changeStatus = function(status) {
    $scope.status = status;
  };
};
module.exports = BookingCtrl;
BookingCtrl.$inject = ['$scope', '$route', '$translate'];
