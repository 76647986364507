// TO DO duplicated with , remove server/app/cloudbeds/util.js
/* global moment */
/**
* Used at both Cloudbeds & Room availability (getter)
*/
module.exports = {
  // datets @moment
  create: function(datets) {
    return moment(datets).format('YYYY-MM-DD');
  },
  asMoment: function(date) {
    return moment(date, 'YYYY-MM-DD');
  },
};
