/**
 * Get roomType key used as roomTypeNameShort in cloudbeds records
 * TODO create true instance such that possible to get id
 * @type {Object}
 */
module.exports = {
  create: function(bookingType, numHour, numNight) {
    if (bookingType === 'night') {
      if (!numHour) {
        return '20H';
      } else if (numHour - (numNight - 1) * 24 === 12) {
        return '12H';
      } else if (numHour - (numNight - 1) * 24 === 20) {
        return '20H';
      }
    }
    // handle < 1 Hour
    if (numHour < 1) {
      return numHour * 60 + 'M';
    }
    return numHour + 'H';
  },
  present: function(numHour) {
    if (numHour < 1) {
      return numHour * 60 + 'm';
    }
    return numHour + 'h';
  },
  getPlan: function(numHour) {
    // TO DO: i18n
    if (numHour < 1) {
      return numHour * 60 + 'mins Plan';
    }
    return numHour + 'hr Plan';
  },
};
