function mdDateLocaleProviderConfig($mdDateLocaleProvider) {
  $mdDateLocaleProvider.formatDate = function(date) {
    return date ? moment(date).format('DD MMM YYYY') : '';
  };

  $mdDateLocaleProvider.parseDate = function(dateString) {
    let m = moment(dateString, 'DD/MM/YYYY', true);
    if (m.isValid()) {
      return m.toDate();
    }
    m = moment(dateString, 'DD MMM YYYY', true);
    return m.isValid() ? m.toDate() : new Date(NaN);
  };
}

mdDateLocaleProviderConfig.$inject = ['$mdDateLocaleProvider'];
module.exports = mdDateLocaleProviderConfig;
