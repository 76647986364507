const template = require('./done.html');
require('./done.css');

const DoneCtrl = function($scope, $location, FlowSrvc, ReserveDetailsSrvc, $http, $interval, $timeout) {
  let redirect_time = 45000;
  const notice = $interval(function() {
    redirect_time -= 1000;
    const time_left = redirect_time / 1000;
    $scope.vm.timer = `${time_left}`;
  }, 1000);

  $timeout(function() {
    $interval.cancel(notice);
    // clear all params
    $location.search({});
    FlowSrvc.toFlow('CHECKIN_KIOSK');
    FlowSrvc.entry('withoutHistory');
  }, redirect_time);

  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  // force enter & override
  $scope.vm.done = false;
  $scope.vm.timer = '45';
  $scope.vm.openDoorPromise = $http.get('https://warp.sleeep.io/?key=sesamum')
      .then(function() {
        $scope.vm.done = true;
        global.firebase.database().ref('doorLastOpened')
            .set(moment().valueOf());
      })
      .catch(function(error) {
        console.log('Error in opening door');
        // TO DO such back url should be inside the flow logic instead
        FlowSrvc.error(error, '/checkin_kiosk/start');
      });

  const cbId = $scope.vm.reserveDetails.reservation.pmsReservationId;
  // silent request
  global.firebase.database().ref(APP_CONFIG.FIREBASE.TRELLO_BY_CBID + cbId + '/email')
      .set($scope.vm.reserveDetails.guest.email)
      .then(function() {
        return $http.post(APP_CONFIG.API_PATH + '/checkin-by-ota', {
          reservationID: cbId,
        });
      });
};

DoneCtrl.$inject = ['$scope', '$location', 'FlowSrvc', 'ReserveDetailsSrvc', '$http', '$interval', '$timeout'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: DoneCtrl,
};
